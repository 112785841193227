import {PERMISSIONS, DELETE_PERMISSION, LOADING_PERMISSIONS, SELECTED_PERMISSION, UPDATE_PERMISSION} from '../../actions/types';

const initialState = {
        loading: false,
        permission: 1,
        permissions: []
    }
;

export default function PermissionsReducer(state = initialState, action) {

    switch (action.type) {
        case PERMISSIONS:
            return {
                ...state,
                permissions: action.payload,
                loading: false
            };
        case DELETE_PERMISSION:
            return {
                ...state,
                permissions: action.payload,
                loading: false
            };
        case UPDATE_PERMISSION:
            return {
                ...state,
                permissions: action.payload,
                loading: false
            };
        case SELECTED_PERMISSION:
            return {
                ...state,
                permission: action.payload
            };
        case LOADING_PERMISSIONS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
