import {COUNTRIES, DELETE_COUNTRY, LOADING_COUNTRIES, SELECTED_COUNTRY, UPDATE_COUNTRY} from '../../actions/types';

const initialState = {
        loading: false,
        country: {
            id: 1,
            code: 'MX',
            nombre: 'MEXICO',
            bandera: 'mx'
        },
        countries: []
    }
;

export default function countriesReducer(state = initialState, action) {

    switch (action.type) {
        case COUNTRIES:
            return {
                ...state,
                countries: action.payload,
                loading: false
            };
        case DELETE_COUNTRY:
            return {
                ...state,
                countries: action.payload,
                loading: false
            };
        case UPDATE_COUNTRY:
            return {
                ...state,
                countries: action.payload,
                loading: false
            };
        case SELECTED_COUNTRY:
            return {
                ...state,
                country: action.payload
            };
        case LOADING_COUNTRIES:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}