import React, {useEffect} from 'react';
import {Grid, Image, Popup, Segment} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {fetchMenus, selectMenu} from '../../redux/actions/index';
import {AppEnv} from '../env';

function Menu() {
    const dispatch = useDispatch();
    const userRole = useSelector(state => state.root.userRole);
    const token = useSelector(state => state.root.token);
    const menus = useSelector(state => state.menu.menus);
    const selectedMenu = useSelector(state => state.menu.selectedMenu) || '';
    const menusRendered = menus.map(it =>
        <Popup
            key={it.id}
            trigger={<Grid.Column
                className='borderRadius'
                computer={7}
                mobile={8}
                style={{padding: 5}}
                tablet={8}
                textAlign='center'>
                <Segment textAlign='center'
                         style={{backgroundColor: selectedMenu === it.web ? AppEnv.mainColor : null, padding: 8}}
                         onClick={() => dispatch(selectMenu(it.web))}>
                    <Image src={it.url} size='tiny' centered/>
                    <Grid className='no-margin'>
                        <Grid.Row
                            centered
                            style={{padding: 3, color: selectedMenu === it.web ? '#fff' : null}}
                            className='no-margin'
                            only='tablet computer'>
                            <label className='truncate'>{it.nombre}</label>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Grid.Column>}
            content={it.nombre}
            inverted
        />
    );

    useEffect(() => {
        async function asd() {
            if (token !== undefined) {
                await dispatch(fetchMenus(token, userRole));
            }
        }

        asd();
    }, []);

    return (
        <Grid className='no-margin'>
            <Grid.Row
                centered
                className='no-margin'
                column={3}>
                {menusRendered}
            </Grid.Row>
        </Grid>
    );
}

export default Menu;
