import {ROLES, DELETE_ROL, LOADING_ROLES, SELECTED_ROL, UPDATE_ROL} from '../../actions/types';

const initialState = {
        loading: false,
        rol: 1,
        roles: []
    }
;

export default function RolesReducer(state = initialState, action) {

    switch (action.type) {
        case ROLES:
            return {
                ...state,
                roles: action.payload,
                loading: false
            };
        case DELETE_ROL:
            return {
                ...state,
                roles: action.payload,
                loading: false
            };
        case UPDATE_ROL:
            return {
                ...state,
                roles: action.payload,
                loading: false
            };
        case SELECTED_ROL:
            return {
                ...state,
                rol: action.payload
            };
        case LOADING_ROLES:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}