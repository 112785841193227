import {DELETE_PLANES, LOADING_PLANES, PLANES, SELECTED_PLAN, TEMAS, UPDATE_PLAN} from '../../actions/types';

const initialState = {
        loading: false,
        plan: 0,
        planes: [],
        tema: 0,
        temas: []
    }
;

export default function PlanEstudioReducer(state = initialState, action) {

    switch (action.type) {
        case TEMAS:
            return {
                ...state,
                temas: action.payload,
                loading: false
            };
        case PLANES:
            return {
                ...state,
                planes: action.payload,
                loading: false
            };
        case DELETE_PLANES:
            return {
                ...state,
                planes: action.payload,
                loading: false
            };
        case UPDATE_PLAN:
            return {
                ...state,
                planes: action.payload,
                loading: false
            };
        case SELECTED_PLAN:
            return {
                ...state,
                plan: action.payload
            };
        case LOADING_PLANES:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}