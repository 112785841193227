import {LOADING_PERMISSIONS, PERMISSIONS, SELECTED_PERMISSION} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_PERMISSIONS,
        payload: data
    };
}

export function createPermission(token, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'permissions/create', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchPermissions(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchPermissions(token));
        });
    };
}

export function fetchPermissions(token) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'permissions/get', {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchPermissionsSuccess(response.data));
        }).catch(error => {
            dispatch(fetchPermissionsFailed());
        });
    };
}

export function updatePermission(token, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'permissions/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchPermissions(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchPermissions(token));
        });
    };
}

export function deletePermission(token, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'permissions/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchPermissions(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchPermissions(token));
        });
    };
}

export function fetchPermissionsSuccess(response) {
    return {
        type: PERMISSIONS,
        payload: response
    };
}

export function fetchPermissionsFailed() {
    Functions.alertFail();
    return {
        type: PERMISSIONS,
        payload: []
    };
}

export function setPermission(permission) {
    return {
        type: SELECTED_PERMISSION,
        payload: permission
    };
}
