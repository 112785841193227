export const LOGIN = 'LOGIN';
export const LOADING = 'LOADING';
export const LOGOUT = 'LOGOUT';
export const SET_MARCA = 'SET_MARCA';
export const APELLIDO_MATERNO = 'APELLIDO_MATERNO';

// <=<●>=><=<●>=><=<●>=> Menu <=<●>=><=<●>=><=<●>=>
export const MENUS = 'MENUS';
export const SELECTED_MENU = 'SELECTED_MENU';
export const HOME = 'HOME';
export const LOADING_MENU = 'LOADING_MENU';

// <=<●>=><=<●>=><=<●>=> Countries <=<●>=><=<●>=><=<●>=>
export const COUNTRIES = 'COUNTRIES';
export const LOADING_COUNTRIES = 'LOADING_COUNTRIES';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const SELECTED_COUNTRY = 'SELECTED_COUNTRY';

// <=<●>=><=<●>=><=<●>=> Partners <=<●>=><=<●>=><=<●>=>
export const UPDATE_PARTNERS = 'UPDATE_PARTNERS';
export const DELETE_PARTNERS = 'DELETE_PARTNERS';
export const PARTNERS = 'PARTNERS';
export const LOADING_PARTNERS = 'LOADING_PARTNERS';

// <=<●>=><=<●>=><=<●>=> Niveles <=<●>=><=<●>=><=<●>=>
export const NIVELES = 'NIVELES';
export const DELETE_NIVEL = 'DELETE_NIVEL';
export const LOADING_NIVELES = 'LOADING_NIVELES';
export const SELECTED_NIVEL = 'SELECTED_NIVEL';
export const UPDATE_NIVEL = 'UPDATE_NIVEL';

// <=<●>=><=<●>=><=<●>=> Ciclos <=<●>=><=<●>=><=<●>=>
export const CICLOS = 'CICLOS';
export const DELETE_CICLO = 'DELETE_CICLO';
export const LOADING_CICLOS = 'LOADING_CICLOS';
export const SELECTED_CICLO = 'SELECTED_CICLO';
export const UPDATE_CICLO = 'UPDATE_CICLO';

// <=<●>=><=<●>=><=<●>=> Grados <=<●>=><=<●>=><=<●>=>
export const GRADOS = 'GRADOS';
export const DELETE_GRADO = 'DELETE_GRADO';
export const LOADING_GRADOS = 'LOADING_GRADOS';
export const SELECTED_GRADO = 'SELECTED_GRADO';
export const UPDATE_GRADO = 'UPDATE_GRADO';

// <=<●>=><=<●>=><=<●>=> Periodos <=<●>=><=<●>=><=<●>=>
export const ALIAS = 'ALIAS';
export const DELETE_ALIAS = 'DELETE_ALIAS';
export const LOADING_ALIAS = 'LOADING_ALIAS';
export const SELECTED_ALIAS = 'SELECTED_ALIAS';
export const UPDATE_ALIAS = 'UPDATE_ALIAS';

// <=<●>=><=<●>=><=<●>=> Calendarios <=<●>=><=<●>=><=<●>=>
export const CALENDARIOS = 'CALENDARIOS';
export const DELETE_CALENDARIO = 'DELETE_CALENDARIO';
export const LOADING_CALENDARIOS = 'LOADING_CALENDARIOS';
export const SELECTED_CALENDARIO = 'SELECTED_CALENDARIO';
export const UPDATE_CALENDARIO = 'UPDATE_CALENDARIO';

// <=<●>=><=<●>=><=<●>=> Calendarios <=<●>=><=<●>=><=<●>=>
export const MATERIAS = 'MATERIAS';
export const DELETE_MATERIA = 'DELETE_MATERIA';
export const LOADING_MATERIAS = 'LOADING_MATERIAS';
export const SELECTED_MATERIA = 'SELECTED_MATERIA';
export const UPDATE_MATERIA = 'UPDATE_MATERIA';

// <=<●>=><=<●>=><=<●>=> Calendarios <=<●>=><=<●>=><=<●>=>
export const TYPE_EVENTS = 'TYPE_EVENTS';
export const DELETE_TYPE_EVENT = 'DELETE_TYPE_EVENT';
export const LOADING_TYPE_EVENTS = 'LOADING_TYPE_EVENTS';
export const SELECTED_TYPE_EVENT = 'SELECTED_TYPE_EVENT';
export const UPDATE_TYPE_EVENT = 'UPDATE_TYPE_EVENT';
export const SELECTED_COLOR = 'SELECTED_COLOR';

// <=<●>=><=<●>=><=<●>=> Niveles <=<●>=><=<●>=><=<●>=>
export const TIPOS_NIVELES = 'TIPOS_NIVELES';
export const DELETE_TIPOS_NIVEL = 'DELETE_TIPOS_NIVEL';
export const LOADING_TIPOS_NIVELES = 'LOADING_TIPOS_NIVELES';
export const SELECTED_TIPOS_NIVEL = 'SELECTED_TIPOS_NIVEL';
export const UPDATE_TIPOS_NIVEL = 'UPDATE_TIPOS_NIVEL';

// <=<●>=><=<●>=><=<●>=> Plan de estudios <=<●>=><=<●>=><=<●>=>
export const PLANES = 'PLANES';
export const DELETE_PLANES = 'DELETE_PLANES';
export const LOADING_PLANES = 'LOADING_PLANES';
export const SELECTED_PLAN = 'SELECTED_PLAN';
export const UPDATE_PLAN = 'UPDATE_PLAN';

export const TEMAS = 'TEMAS';

// <=<●>=><=<●>=><=<●>=> Globales <=<●>=><=<●>=><=<●>=>

export const GLOBALS = 'GLOBALS';
export const LOADING_GLOBALS = 'LOADING_GLOBALS';
export const UPDATE_GLOBALS = 'UPDATE_GLOBALS';

export const DATA_BASE = 'DATA_BASE';
export const ID_CIUDADANO = 'ID_CIUDADANO';
export const ALIAS_ID_CIUDADANO = 'ALIAS_ID_CIUDADANO';
export const ALIAS_ID_ESCUELA = 'ALIAS_ID_ESCUELA';
export const ID_ESCUELA = 'ID_ESCUELA';
export const CODIGO_POSTAL = 'CODIGO_POSTAL';
export const ALIAS_COLONIA = 'ALIAS_COLONIA';
export const ALIAS_CIUDAD = 'ALIAS_CIUDAD';
export const ESTADO = 'ESTADO';
export const ALIAS_ESTADO = 'ALIAS_ESTADO';

export const ROLES = 'ROLES';
export const DELETE_ROL = 'DELETE_ROL';
export const LOADING_ROLES = 'LOADING_ROLES';
export const SELECTED_ROL = 'SELECTED_ROL';
export const UPDATE_ROL = 'UPDATE_ROL';

// <=<●>=><=<●>=><=<●>=> Permisos <=<●>=><=<●>=><=<●>=>

export const LOADING_PERMISSIONS = 'LOADING_PERMISSIONS';
export const PERMISSIONS = 'PERMISSIONS';
export const SELECTED_PERMISSION = 'SELECTED_PERMISSION';
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const DELETE_PERMISSION = 'DELETE_PERMISSION';

// <=<●>=><=<●>=><=<●>=> Tipo de materias <=<●>=><=<●>=><=<●>=>

export const TIPOS_MATERIAS = 'TIPOS_MATERIAS';
export const DELETE_TIPO_MATERIA = 'DELETE_TIPO_MATERIA';
export const LOADING_TIPOS_MATERIAS = 'LOADING_TIPOS_MATERIAS';
export const SELECTED_TIPO_MATERIA = 'SELECTED_TIPO_MATERIA';
export const UPDATE_TIPO_MATERIA = 'UPDATE_TIPO_MATERIA';

// <=<●>=><=<●>=><=<●>=> tipo de calendario <=<●>=><=<●>=><=<●>=>

export const DELETE_TIPO_CALENDARIO = 'DELETE_TIPO_CALENDARIO';
export const LOADING_TIPOS_CALENDARIOS = 'LOADING_TIPOS_CALENDARIOS';
export const SELECTED_TIPO_CALENDARIO = 'SELECTED_TIPO_CALENDARIO';
export const TIPOS_CALENDARIOS = 'TIPOS_CALENDARIOS';
export const UPDATE_TIPO_CALENDARIO = 'UPDATE_TIPO_CALENDARIO';

// <=<●>=><=<●>=><=<●>=> avisos <=<●>=><=<●>=><=<●>=>

export const AVISOS_DESTINATARIOS = 'AVISOS_DESTINATARIOS';
export const GET_AVISOS = 'GET_AVISOS';
export const LOADING_AVISOS = 'LOADING_AVISOS';
export const DELETE_AVISO = 'DELETE_AVISO';
export const UPDATE_AVISO = 'UPDATE_AVISO';
