import {CALENDARIOS, DELETE_CALENDARIO, LOADING_CALENDARIOS, SELECTED_CALENDARIO, UPDATE_CALENDARIO} from '../../actions/types';

const initialState = {
        loading: false,
        calendario: 1,
        calendarios: []
    }
;

export default function CalendariosReducer(state = initialState, action) {

    switch (action.type) {
        case CALENDARIOS:
            return {
                ...state,
                calendarios: action.payload,
                loading: false
            };
        case DELETE_CALENDARIO:
            return {
                ...state,
                calendarios: action.payload,
                loading: false
            };
        case UPDATE_CALENDARIO:
            return {
                ...state,
                calendarios: action.payload,
                loading: false
            };
        case SELECTED_CALENDARIO:
            return {
                ...state,
                calendario: action.payload
            };
        case LOADING_CALENDARIOS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}