// Dependencies
import React, {Component} from 'react';
import {store} from 'react-notifications-component';
import {Icon, Message} from 'semantic-ui-react';

class Functions extends Component {
    static alertFail(title = 'Ha ocurrido un error inesperado', text = 'Ha ocurrido un error, si el error continúa pónganse en contacto con soporte@kolegia.com') {
        store.addNotification({
            title: title,
            message: text,
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 4000,
                onScreen: true
            }
        });
    };

    static alertSuccess(title = 'Felicidades', text = 'Acción completada con éxito') {
        store.addNotification({
            title: title,
            message: text,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 4000,
                onScreen: true
            }
        });
    };

    static alertWarning(title = 'Ups algo no está bien', text = 'Revise todos los datos') {
        store.addNotification({
            title: title,
            message: text,
            type: 'warning',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 4000,
                onScreen: true
            }
        });
    };

    static loadingMessage(title = 'Espere un momento', text = ' Estamos actualizando la información.') {
        return <Message icon>
            <Icon name='circle notched' loading/>
            <Message.Content>
                <Message.Header>{title}</Message.Header>
                {text}
            </Message.Content>
        </Message>;

    };

    static nothingMessage(title = 'Al parecer aún no hay datos', text = 'Trate con una nueva consulta o intente más tarde') {
        return <Message
            warning
            header={title}
            content={text}
        />;

    };

    static currency(num) {
        return Number(num).toLocaleString('es-MX', {
            currency: 'MXN',
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    }

}

export default Functions; 
 