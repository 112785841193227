import {
    ALIAS_COLONIA,
    ALIAS_ESTADO,
    ALIAS_CIUDAD,
    ESTADO,
    ALIAS_ID_CIUDADANO,
    ALIAS_ID_ESCUELA,
    APELLIDO_MATERNO,
    CODIGO_POSTAL,
    DATA_BASE,
    GLOBALS,
    ID_CIUDADANO,
    ID_ESCUELA,
    LOADING_GLOBALS,
    UPDATE_GLOBALS
} from '../../actions/types';

const initialState = {
        loading: false,
        data_base: '0',
        apellido_materno: '1',
        id_ciudadano: '1',
        alias_id_ciudadano: 'CURP',
        id_escuela: '0',
        alias_id_escuela: 'CCT',
        codigo_postal: '0',
        alias_colonia: 'Colonia',
        alias_ciudad: 'Ciudad',
        estado: '1',
        alias_estado: 'Estado'
    }
;

export default function GlobalsReducer(state = initialState, action) {

    switch (action.type) {
        case GLOBALS:
            return {
                ...state,
                data_base: action.payload.data_base,
                apellido_materno: action.payload.apellido_materno,
                id_ciudadano: action.payload.id_ciudadano,
                alias_id_ciudadano: action.payload.alias_id_ciudadano,
                id_escuela: action.payload.id_escuela,
                alias_id_escuela: action.payload.alias_id_escuela,
                codigo_postal: action.payload.codigo_postal,
                alias_colonia: action.payload.alias_colonia,
                alias_ciudad: action.payload.alias_ciudad,
                estado: action.payload.estado,
                alias_estado: action.payload.alias_estado,
                loading: false
            };
        case UPDATE_GLOBALS:
            return {
                ...state,
                data_base: action.payload.data_base,
                apellido_materno: action.payload.apellido_materno,
                id_ciudadano: action.payload.id_ciudadano,
                alias_id_ciudadano: action.payload.alias_id_ciudadano,
                id_escuela: action.payload.id_escuela,
                alias_id_escuela: action.payload.alias_id_escuela,
                codigo_postal: action.payload.codigo_postal,
                alias_colonia: action.payload.alias_colonia,
                alias_ciudad: action.payload.alias_ciudad,
                estado: action.payload.estado,
                alias_estado: action.payload.alias_estado,
                loading: false
            };
        case LOADING_GLOBALS:
            return {
                ...state,
                loading: action.payload
            };
        case DATA_BASE:
            return {
                ...state,
                data_base: action.payload,
                loading: false
            };
        case APELLIDO_MATERNO:
            return {
                ...state,
                apellido_materno: action.payload,
                loading: false
            };
        case ID_CIUDADANO:
            return {
                ...state,
                id_ciudadano: action.payload,
                loading: false
            };
        case ALIAS_ID_CIUDADANO:
            return {
                ...state,
                alias_id_ciudadano: action.payload,
                loading: false
            };
        case ID_ESCUELA:
            return {
                ...state,
                id_escuela: action.payload,
                loading: false
            };
        case ALIAS_ID_ESCUELA:
            return {
                ...state,
                alias_id_escuela: action.payload,
                loading: false
            };
        case CODIGO_POSTAL:
            return {
                ...state,
                codigo_postal: action.payload,
                loading: false
            };
        case ALIAS_COLONIA:
            return {
                ...state,
                alias_colonia: action.payload,
                loading: false
            };
        case ALIAS_CIUDAD:
            return {
                ...state,
                alias_ciudad: action.payload,
                loading: false
            };
        case ESTADO:
            return {
                ...state,
                estado: action.payload,
                loading: false
            };
        case ALIAS_ESTADO:
            return {
                ...state,
                alias_estado: action.payload,
                loading: false
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
