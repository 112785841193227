// Dependencies
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Components
import Content from './Content';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

class App extends Component {
    static propTypes = {
        children: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {children} = this.props;
        return (
            <div className="App">
                <ReactNotification/>
                <Content body={children}/>
            </div>
        );
    }
}

export default App;
