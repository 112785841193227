import React from 'react';
import {Grid, Header} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useSelector} from 'react-redux';
import MenuConfiguracion from '../Configuracion/MenuConfiguracion';
import Partners from '../Partners/Partners';
import CreateRoles from '../Roles/CreateRoles';
import CrearAviso from "../Avisos/CrearAviso";

function DisplayMenu() {
    const userName = useSelector(state => state.root.userName);
    const selectedMenu = useSelector(state => state.menu.selectedMenu) || '';

    const inicio = <Grid centered className='no-margin'>
        <Header as='h2'>
            <img alt='' className='lazyload' data-src='https://img.icons8.com/ios/96/000000/shooting-stars.png'/>
            <Header.Content>
                Bienvenido
                <br/>
                {userName}
                <Header.Subheader>Para continuar, selecciona una opción del menú que está a la
                    izquierda</Header.Subheader>
            </Header.Content>
        </Header>
    </Grid>;

    const noDisponible = <Grid centered className='no-margin'>
        <Header as='h2'>
            <img alt='' className='lazyload' data-src='https://img.icons8.com/ios/96/000000/maintenance.png'/>
            <Header.Content>
                Bienvenido
                <br/>
                {userName}
                <Header.Subheader> Funcionalidad en proceso de optimización. Disponible en la app
                    móvil.</Header.Subheader>
            </Header.Content>
        </Header>
    </Grid>;

    switch (selectedMenu) {
        case 'Paises':
            return <MenuConfiguracion/>;
        case 'Partners':
            return <Partners/>;
        case 'Roles':
            return <CreateRoles/>;
        case 'Avisos':
            return <CrearAviso/>;
        case '':
            return inicio;
        default:
            return noDisponible;
    }

}

export default DisplayMenu;
