// Dependencies
import React, {Component} from 'react';
import {Col, Grid, Row} from 'react-material-responsive-grid';

class Page404 extends Component {
    render() {
        return <Grid
            style={{
                margin: 0,
                padding: 0,
                // backgroundImage: 'url(/images/fondos/salon8.webp)',
                backgroundSize: 'cover'
            }}
            className="full">
            <Col
                lg={12}
                xl={12}
                md={12}
                sm={12}
                style={{paddingTop: 200, paddingBottom: 200}}
                className="full">
                <Row className="centerAll ">
                    <div className="callto404">
                        <img alt='' className='lazyload'
                             data-src="https://png.icons8.com/color/100/000000/road-closure.png"/>
                    </div>
                </Row>
                <Row
                    styel={{
                        margin: 0,
                        padding: 0
                    }}
                    className="centerAll ">
                    <div style={{color: 'green'}} className="encabezado callto404">
                        Lo sentimos, esta dirección no existe.
                    </div>
                </Row>
                <Row
                    styel={{
                        margin: 0,
                        padding: 0
                    }}
                    className="centerAll subtitulo ">
                    <div className=" callto404">
                        Siempre estará disponible nuestra{' '}
                        <a href={'/'}>Página principal</a>
                    </div>
                </Row>
            </Col>
        </Grid>;
    }
}

export default Page404;