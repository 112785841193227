import {CICLOS, DELETE_CICLO, LOADING_CICLOS, SELECTED_CICLO, UPDATE_CICLO} from '../../actions/types';

const initialState = {
        loading: false,
        ciclo: 1,
        ciclos: []
    }
;

export default function NivelesReducer(state = initialState, action) {

    switch (action.type) {
        case CICLOS:
            return {
                ...state,
                ciclos: action.payload,
                loading: false
            };
        case DELETE_CICLO:
            return {
                ...state,
                ciclos: action.payload,
                loading: false
            };
        case UPDATE_CICLO:
            return {
                ...state,
                ciclos: action.payload,
                loading: false
            };
        case SELECTED_CICLO:
            return {
                ...state,
                ciclo: action.payload
            };
        case LOADING_CICLOS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}