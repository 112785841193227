import {LOADING_NIVELES, NIVELES, SELECTED_NIVEL} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_NIVELES,
        payload: data
    };
}

export function createNivel(token, country, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'niveles/create', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchNiveles(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchNiveles(token, country));
        });
    };
}

export function fetchNiveles(token, country) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'niveles/get/' + country, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchNivelesSuccess(response.data));
        }).catch(error => {
            dispatch(fetchNivelesFailed());
        });
    };
}

export function updateNivel(token, country, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'niveles/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchNiveles(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchNiveles(token, country));
        });
    };
}

export function deleteNivel(token, country, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'niveles/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchNiveles(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchNiveles(token, country));
        });
    };
}

export function fetchNivelesSuccess(response) {
    return {
        type: NIVELES,
        payload: response
    };
}

export function fetchNivelesFailed() {
    Functions.alertFail();
    return {
        type: NIVELES,
        payload: []
    };
}

export function setNivel(Nivel) {
    return {
        type: SELECTED_NIVEL,
        payload: Nivel
    };
}