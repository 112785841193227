import React, {useEffect, useState} from 'react';
import {
    Button,
    Confirm,
    Divider,
    Grid,
    Header,
    Icon,
    Input,
    Item,
    Modal,
    Popup,
    Select,
    TextArea
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {fetchTipoNiveles, setTipoNivel} from '../../redux/actions/CountriesActions/TipoNivelesActions';
import {
    createPlan,
    createTema,
    deletePlan,
    fetchPlanes,
    fetchTemas,
    setPlan,
    updatePlan,
    updateTema
} from '../../redux/actions/CountriesActions/PlanEstudioActions';
import Functions from '../Global/Functions';
import {fetchNiveles, setNivel} from "../../redux/actions/CountriesActions/NivelesActions";

function PlanEstudios() {
    const [aprendizajes, setAprendizajes] = useState([]);
    const [aux, setAux] = useState(1);
    const [competencias, setCompetencias] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [indice, setIndice] = useState('');
    const [modal, setModal] = useState(false);
    const [modalAprendizajes, setModalAprendizajes] = useState(false);
    const [modalCompetencias, setModalCompetencias] = useState(false);
    const [modalCrearAprendizajes, setModalCrearAprendizajes] = useState(false);
    const [modalCrearCompetencias, setModalCrearCompetencias] = useState(false);
    const [modalEditAprendizajes, setModalEditAprendizajes] = useState(false);
    const [modalEditCompetencias, setModalEditCompetencias] = useState(false);
    const [modalEditTema, setModalEditTema] = useState(false);
    const [nombre, setNombre] = useState('');
    const [selected, setSelected] = useState('');
    const [selectedAprendizajes, setSelectedAprendizajes] = useState([]);
    const [selectedCompetencias, setSelectedCompetencias] = useState([]);
    const [selectedTema, setSelectedTema] = useState('');
    const [selectedTemaIndice, setSelectedTemaIndice] = useState('');
    const [selectedTemaName, setSelectedTemaName] = useState('');
    const [temaName, setTemaName] = useState('');
    const [verAprendizajes, setVerAprendizajes] = useState([]);
    const [verCompetencias, setVerCompetencias] = useState([]);
    const tipoNivel = useSelector(state => state.tipo_niveles.tipo_nivel);
    const tipoNiveles = useSelector(state => state.tipo_niveles.tipo_niveles);
    const planes = useSelector(state => state.plan_estudios.planes);
    const plan = useSelector(state => state.plan_estudios.plan);
    const temas = useSelector(state => state.plan_estudios.temas);
    const tema = useSelector(state => state.plan_estudios.tema);
    const loading = useSelector(state => state.plan_estudios.loading);
    const country = useSelector(state => state.countries.country);
    const niveles = useSelector(state => state.niveles.niveles);
    const nivel = useSelector(state => state.niveles.nivel);
    const token = useSelector(state => state.root.token);
    const dispatch = useDispatch();

    const renderNiveles = niveles.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));

    const renderTipoNiveles = tipoNiveles.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));


    const save = () => {
        if (nivel && nombre) {
            let fd = JSON.stringify({
                id_pais: country.id,
                id_nivel: nivel,
                tipo_nivel: tipoNivel,
                nombre: nombre.toUpperCase()
            });
            dispatch(createPlan(token, country.id, nivel, tipoNivel, fd));
            setNombre('');

        } else {
            Functions.alertWarning();
        }
    };

    const edit = () => {
        if (nivel && nombre) {
            let fd = JSON.stringify({
                id_pais: country.id,
                id_nivel: nivel,
                tipo_nivel: tipoNivel,
                nombre: nombre.toUpperCase()
            });
            dispatch(updatePlan(token, country.id, nivel, tipoNivel, fd, selected.id));
            setSelected('');
            setNombre('');
            setModal(false);
        } else {
            Functions.alertWarning();
        }
    };

    const planesRendered = planes.map(it =>
        (<Item key={it.id}>
            <Item.Content verticalAlign='middle'>
                <Item.Header>{it.nombre}</Item.Header>
                <Item.Meta>
                    <span className='cinema'>Nivel: {it.nivel.nombre}</span>
                </Item.Meta>
                <Item.Meta>
                    <span className='cinema'>Tipo de nivel: {it.tipo_nivel.nombre}</span>
                </Item.Meta>
                <Item.Extra floated='right'>
                    <Button size='mini' content='Borrar' icon='delete' labelPosition='left' onClick={() => {
                        setSelected(it);
                        setConfirmDelete(true);
                    }}/>
                    <Button
                        size='mini'
                        content='Editar'
                        icon='edit'
                        labelPosition='right'
                        onClick={() => {
                            setSelected(it);
                            dispatch(setPlan(Number(it.id)));
                            setNombre(it.nombre);
                            setModal(true);
                        }}/>
                    <Button
                        size='mini'
                        content='Seleccionar'
                        icon='checkmark'
                        labelPosition='right'
                        onClick={() => {
                            dispatch(setPlan(Number(it.id)));
                            setSelected(it);
                        }}/>
                </Item.Extra>
            </Item.Content>
        </Item>)
    );


    const temasRendered = temas.map(it =>
        (<Item key={it.id}>
            <Grid centered className='no-margin'>
                <Grid.Row
                    centered
                    stretched
                    verticalAlign='middle'
                    columns={4}
                    style={{
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        borderRadius: 6,
                        padding: '5px 0',
                        margin: '0 0 4px 0'
                    }}>
                    <Grid.Column
                        className='col-pad5'
                        computer={2}
                        mobile={2}
                        tablet={2}
                        textAlign='center'>
                        <Input
                            disabled={true}
                            size={'large'}
                            value={it.indice}/>
                    </Grid.Column>

                    <Grid.Column
                        className='col-pad5'
                        computer={8}
                        mobile={8}
                        tablet={8}
                        textAlign='center'>
                        <Input
                            disabled={true}
                            size={'large'}
                            value={it.tema}/>
                    </Grid.Column>

                    <Grid.Column
                        className='col-pad5'
                        computer={2}
                        mobile={2}
                        tablet={2}
                        textAlign='center'>
                        <Popup
                            content={'Aprendizajes'}
                            trigger={
                                <Button
                                    color='teal'
                                    loading={loading}
                                    content={JSON.parse(it.aprendizajes).length}
                                    labelPosition='left'
                                    onClick={() => {
                                        setVerAprendizajes(JSON.parse(it.aprendizajes));
                                        setModalAprendizajes(true)
                                    }}
                                    icon='caret square up outline'/>}
                        />
                    </Grid.Column>

                    <Grid.Column
                        className='col-pad5'
                        computer={2}
                        mobile={2}
                        tablet={2}
                        textAlign='center'>
                        <Popup
                            content={'Competencias'}
                            trigger={
                                <Button
                                    color='teal'
                                    loading={loading}
                                    content={JSON.parse(it.competencias).length}
                                    labelPosition='left'
                                    onClick={() => {
                                        setVerCompetencias(JSON.parse(it.competencias));
                                        setModalCompetencias(true)
                                    }}
                                    icon='caret square up outline'/>}
                        />
                    </Grid.Column>

                    <Grid.Column
                        className='col-pad5'
                        computer={2}
                        mobile={2}
                        tablet={2}
                        textAlign='center'>
                        <Popup
                            content={'Editar'}
                            trigger={<Button
                                color='teal'
                                loading={loading}
                                onClick={() => {
                                    setSelectedTema(it.id)
                                    setSelectedTemaName(it.tema)
                                    setSelectedTemaIndice(it.indice)
                                    setSelectedAprendizajes(JSON.parse(it.aprendizajes))
                                    setModalEditTema(true)
                                }}
                                icon='edit'/>}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>)
    );

    const saveTema = () => {
        if (indice && temaName && plan !== 0) {
            let fd = JSON.stringify({
                id_plan: plan,
                indice: indice,
                tema: temaName,
                aprendizajes: JSON.stringify(aprendizajes),
                competencias: JSON.stringify(competencias)
            });
            dispatch(createTema(token, plan, fd));
            setTemaName('');
            setIndice('');

        } else {
            Functions.alertWarning();
        }
    };

    const editTema = () => {
        if (selectedTemaIndice && selectedTemaName) {
            let fd = JSON.stringify({
                id_plan: plan,
                indice: selectedTemaIndice,
                tema: selectedTemaName,
                aprendizajes: JSON.stringify(selectedAprendizajes),
                competencias: JSON.stringify(selectedCompetencias)
            });
            dispatch(updateTema(token, plan, fd, selectedTema));
            setSelectedTemaName('');
            setSelectedTemaIndice('');
            setSelectedAprendizajes([]);
            setSelectedCompetencias([]);
            setModalEditTema(false)
        } else {
            Functions.alertWarning();
        }
    };

    useEffect(() => {
        dispatch(fetchNiveles(token, country.id));
    }, [country]);

    const aprendizajesRendered = aprendizajes.map((it, i) =>
        (<Item key={i + 'a'}>
            <Grid centered className='no-margin'>
                <Grid.Row
                    centered
                    className='no-margin'
                    stretched
                    verticalAlign='middle'>
                    <Grid.Column
                        computer={16}
                        mobile={16}
                        tablet={16}
                        textAlign='center'>
                        <label className='truncate subtitle'>Aprendizaje #{i + 1}</label>
                        <TextArea
                            onChange={(e) => {
                                aprendizajes[i] = e.target.value;
                                setAux(aux + 1)
                            }}
                            placeholder='Nombre'
                            size={'large'}
                            value={it}/>
                        <Divider className='divider-5' hidden/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>)
    );
    const competenciasRendered = competencias.map((it, i) =>
        (<Item key={i + 'a'}>
            <Grid centered className='no-margin'>
                <Grid.Row
                    centered
                    className='no-margin'
                    stretched
                    verticalAlign='middle'>
                    <Grid.Column
                        computer={16}
                        mobile={16}
                        tablet={16}
                        textAlign='center'>
                        <label className='truncate subtitle'>Competencia #{i + 1}</label>
                        <TextArea
                            onChange={(e) => {
                                competencias[i] = e.target.value;
                                setAux(aux + 1)
                            }}
                            placeholder='Nombre'
                            size={'large'}
                            value={it}/>
                        <Divider className='divider-5' hidden/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>)
    );

    const editarAprendizajesRendered = selectedAprendizajes.map((it, i) =>
        (<Item key={i + 'b'}>
            <Grid centered className='no-margin'>
                <Grid.Row
                    centered
                    className='no-margin'
                    stretched
                    verticalAlign='middle'>
                    <Grid.Column
                        computer={16}
                        mobile={16}
                        tablet={16}
                        textAlign='center'>
                        <label className='truncate subtitle'>Aprendizaje #{i + 1}</label>
                        <TextArea
                            onChange={(e) => {
                                selectedAprendizajes[i] = e.target.value;
                                setAux(aux + 1)
                            }}
                            placeholder='Nombre'
                            size={'large'}
                            value={it}/>
                        <Divider className='divider-5' hidden/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>)
    );

    const editarCompetenciasRendered = selectedCompetencias.map((it, i) =>
        (<Item key={i + 'b'}>
            <Grid centered className='no-margin'>
                <Grid.Row
                    centered
                    className='no-margin'
                    stretched
                    verticalAlign='middle'>
                    <Grid.Column
                        computer={16}
                        mobile={16}
                        tablet={16}
                        textAlign='center'>
                        <label className='truncate subtitle'>Competencia #{i + 1}</label>
                        <TextArea
                            onChange={(e) => {
                                selectedCompetencias[i] = e.target.value;
                                setAux(aux + 1)
                            }}
                            placeholder='Nombre'
                            size={'large'}
                            value={it}/>
                        <Divider className='divider-5' hidden/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>)
    );

    const verAprendizajesRendered = verAprendizajes.map((it, i) =>
        (<Item key={i + 'c'}>
            <Grid centered className='no-margin'>
                <Grid.Row
                    centered
                    className='no-margin'
                    stretched
                    verticalAlign='middle'>
                    <Grid.Column
                        computer={16}
                        mobile={16}
                        tablet={16}
                        textAlign='center'>
                        <label className='truncate subtitle'>Aprendizaje #{i + 1}</label>
                        <TextArea
                            disabled={true}
                            placeholder='Nombre'
                            size={'large'}
                            value={it}/>
                        <Divider className='divider-5' hidden/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>)
    );

    const verCompetenciasRendered = verCompetencias.map((it, i) =>
        (<Item key={i + 'c'}>
            <Grid centered className='no-margin'>
                <Grid.Row
                    centered
                    className='no-margin'
                    stretched
                    verticalAlign='middle'>
                    <Grid.Column
                        computer={16}
                        mobile={16}
                        tablet={16}
                        textAlign='center'>
                        <label className='truncate subtitle'>Competencia #{i + 1}</label>
                        <TextArea
                            disabled={true}
                            placeholder='Nombre'
                            size={'large'}
                            value={it}/>
                        <Divider className='divider-5' hidden/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Item>)
    );

    useEffect(() => {
        dispatch(fetchTipoNiveles(token, country.id, nivel));
    }, [nivel]);

    useEffect(() => {
        dispatch(fetchPlanes(token, country.id, nivel, tipoNivel));
    }, [tipoNivel]);

    useEffect(() => {
        if (plan !== 0) {
            dispatch(fetchTemas(token, plan));
        }
    }, [plan]);

    return (
        <Grid className='no-margin' style={{width: '100%'}}>
            {/*<=<●>=><=<●>=><=<●>=> Confirmar Borrado <=<●>=><=<●>=><=<●>=>*/}
            <Confirm
                open={confirmDelete}
                content='¿Está seguro que quiere eliminar este tipo_nivel?'
                cancelButton={<Button color='red' icon='close' content='Cancelar' inverted/>}
                confirmButton={<Button color='green' icon='checkmark' content='Si, eliminar'/>}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={() => [dispatch(deletePlan(token, country.id, nivel, tipoNivel, selected.id)), setConfirmDelete(false)]}
            />

            <Modal size='tiny' open={modal}>
                <Modal.Header>Editando: {selected.nivel !== undefined ? selected.nivel.nombre : null}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    <label className='truncate subtitle'>Seleccione un nivel</label>
                                    <Select
                                        value={nivel}
                                        onChange={(e, data) => dispatch(setNivel(data.value))}
                                        placeholder='Selecciona el nivel'
                                        options={renderNiveles}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione un tipo nivel</label>
                                    <Select
                                        value={tipoNivel}
                                        onChange={(e, data) => dispatch(setTipoNivel(data.value))}
                                        placeholder='Selecciona el tipo de nivel'
                                        options={renderTipoNiveles}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Ingrese un nombre</label>
                                    <Input
                                        onChange={(e) => setNombre(e.target.value)}
                                        placeholder='Nombre'
                                        size={'large'}
                                        value={nombre}/>
                                    <Divider className='divider-5' hidden/>
                                    <Button
                                        fluid
                                        color='teal'
                                        loading={loading}
                                        onClick={edit}
                                        content='Guardar'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModal(false);
                            setSelected('');
                            setNombre('');
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Grid.Row
                stretched
                column={2}
                verticalAlign='top'
                className='no-margin'>
                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Crear plan de estudios</Header>
                    <Grid centered className='no-margin'>
                        <Grid.Row
                            centered
                            className='no-margin scroll1'
                            stretched
                            verticalAlign='middle'>
                            <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                                textAlign='center'>
                                <label className='truncate subtitle'>Seleccione un nivel</label>
                                <Select
                                    value={nivel}
                                    onChange={(e, data) => dispatch(setNivel(data.value))}
                                    placeholder='Selecciona el nivel'
                                    options={renderNiveles}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un tipo nivel</label>
                                <Select
                                    value={tipoNivel}
                                    onChange={(e, data) => dispatch(setTipoNivel(data.value))}
                                    placeholder='Selecciona el tipo de nivel'
                                    options={renderTipoNiveles}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Ingrese un nombre</label>
                                <Input
                                    onChange={(e) => setNombre(e.target.value)}
                                    placeholder='Nombre'
                                    size={'large'}
                                    value={nombre}/>
                                <Divider className='divider-5' hidden/>
                                <Button
                                    fluid
                                    color='teal'
                                    loading={loading}
                                    onClick={save}
                                    content='Guardar'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>

                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Consultar plan de estudios</Header>
                    <Grid.Row
                        stretched
                        className='no-margin scroll1'
                        style={{backgroundColor: '#fff', padding: 10, borderRadius: 10}}>
                        <Item.Group divided relaxed style={{width: '100%'}}>
                            {planes.length !== 0
                                ? loading
                                    ? Functions.loadingMessage()
                                    : planesRendered
                                : loading
                                    ? Functions.loadingMessage()
                                    : Functions.nothingMessage()}
                        </Item.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>

            <Divider style={{margin: 0}}/>

            <Modal size='tiny' open={modalCrearAprendizajes}>
                <Modal.Header>Aprendizajes</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    className='col-pad5'
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    {aprendizajes.length !== 0
                                        ? aprendizajesRendered
                                        : Functions.nothingMessage('Al parecer aún no hay datos', 'Añada un nuevo aprendizaje')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    className='col-pad5'
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    <Button
                                        fluid
                                        color='teal'
                                        loading={loading}
                                        onClick={() => {
                                            aprendizajes.push('');
                                            setAux(aux + 1)
                                        }}
                                        content='Agregar'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModalCrearAprendizajes(false);
                            setSelected('');
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal size='tiny' open={modalEditAprendizajes}>
                <Modal.Header>Aprendizajes</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    className='col-pad5'
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    {selectedAprendizajes.length !== 0
                                        ? editarAprendizajesRendered
                                        : Functions.nothingMessage('Al parecer aún no hay datos', 'Añada un nuevo aprendizaje')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    className='col-pad5'
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    <Button
                                        fluid
                                        color='teal'
                                        loading={loading}
                                        onClick={() => {
                                            selectedAprendizajes.push('');
                                            setAux(aux + 1)
                                        }}
                                        content='Agregar'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModalEditAprendizajes(false);
                            setSelected('');
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal size='tiny' open={modalAprendizajes}>
                <Modal.Header>Aprendizajes</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {verAprendizajes.length !== 0
                            ? verAprendizajesRendered
                            : Functions.nothingMessage('Al parecer aún no hay datos', 'Puede editar y agregar un nuevo aprendizaje')}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModalAprendizajes(false);
                            setSelected('');
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal size='tiny' open={modalCrearCompetencias}>
                <Modal.Header>Competencias</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    className='col-pad5'
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    {competencias.length !== 0
                                        ? competenciasRendered
                                        : Functions.nothingMessage('Al parecer aún no hay datos', 'Añada una nueva competencia')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    className='col-pad5'
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    <Button
                                        fluid
                                        color='teal'
                                        loading={loading}
                                        onClick={() => {
                                            competencias.push('');
                                            setAux(aux + 1)
                                        }}
                                        content='Agregar'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModalCrearCompetencias(false);
                            setSelected('');
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal size='tiny' open={modalEditCompetencias}>
                <Modal.Header>Competencias</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    className='col-pad5'
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    {selectedCompetencias.length !== 0
                                        ? editarCompetenciasRendered
                                        : Functions.nothingMessage('Al parecer aún no hay datos', 'Añada una nueva competencia')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    className='col-pad5'
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    <Button
                                        fluid
                                        color='teal'
                                        loading={loading}
                                        onClick={() => {
                                            selectedCompetencias.push('');
                                            setAux(aux + 1)
                                        }}
                                        content='Agregar'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModalEditCompetencias(false);
                            setSelected('');
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal size='tiny' open={modalCompetencias}>
                <Modal.Header>Competencias</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {verCompetencias.length !== 0
                            ? verCompetenciasRendered
                            : Functions.nothingMessage('Al parecer aún no hay datos', 'Puede editar y agregar un nuevo aprendizaje')}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModalCompetencias(false);
                            setSelected('');
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal size='tiny' open={modalEditTema}>
                <Modal.Header>Editar tema</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Item>
                            <Grid centered className='no-margin'>
                                <Grid.Row
                                    centered
                                    stretched
                                    verticalAlign='middle'
                                    columns={4}
                                    style={{
                                        backgroundColor: 'rgba(255,255,255,0.5)',
                                        borderRadius: 6,
                                        padding: '5px 0',
                                        margin: '0 0 4px 0'
                                    }}>
                                    <Grid.Column
                                        className='col-pad5'
                                        computer={2}
                                        mobile={2}
                                        tablet={2}
                                        textAlign='center'>
                                        <Input
                                            onChange={e => setSelectedTemaIndice(e.target.value)}
                                            size={'large'}
                                            value={selectedTemaIndice}/>
                                    </Grid.Column>

                                    <Grid.Column
                                        className='col-pad5'
                                        computer={9}
                                        mobile={9}
                                        tablet={9}
                                        textAlign='center'>
                                        <Input
                                            onChange={e => setSelectedTemaName(e.target.value)}
                                            size={'large'}
                                            value={selectedTemaName}/>
                                    </Grid.Column>

                                    <Grid.Column
                                        className='col-pad5'
                                        computer={2}
                                        mobile={2}
                                        tablet={2}
                                        textAlign='center'>
                                        <Popup
                                            content={'Aprendizajes'}
                                            trigger={<Button
                                                color='teal'
                                                loading={loading}
                                                onClick={() => setModalEditAprendizajes(true)}
                                                icon='caret square up outline'/>}
                                        />
                                    </Grid.Column>

                                    <Grid.Column
                                        className='col-pad5'
                                        computer={2}
                                        mobile={2}
                                        tablet={2}
                                        textAlign='center'>
                                        <Popup
                                            content={'Competencias'}
                                            trigger={<Button
                                                color='teal'
                                                loading={loading}
                                                onClick={() => setModalEditCompetencias(true)}
                                                icon='caret square up outline'/>}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Item>
                        <Divider className='divider-5' hidden/>
                        <Button
                            fluid
                            color='teal'
                            loading={loading}
                            onClick={editTema}
                            content='Guardar'
                        />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setSelectedTema('')
                            setSelectedTemaName('')
                            setSelectedTemaIndice('')
                            setModalEditTema(false);
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Grid.Row
                stretched
                column={2}
                verticalAlign='top'
                className='no-margin'>
                <Grid.Column
                    computer={16}
                    mobile={16}
                    tablet={16}
                    textAlign='center'>
                    <Header as='h2'>Editar y agregar temas</Header>
                    <Grid centered className='no-margin'>
                        <Grid.Row
                            centered
                            className='no-margin scroll1'
                            stretched
                            verticalAlign='middle'>
                            <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                                textAlign='center'>
                                {temas.length !== 0
                                    ? loading
                                        ? Functions.loadingMessage()
                                        : temasRendered
                                    : loading
                                        ? Functions.loadingMessage()
                                        : Functions.nothingMessage()}

                                {plan !== 0
                                    ? loading
                                        ? null
                                        : <Grid centered className='no-margin'>
                                            <Grid.Row
                                                centered
                                                className='no-margin'
                                                stretched
                                                verticalAlign='middle'
                                                columns={4}>
                                                <Grid.Column
                                                    className='col-pad5'
                                                    computer={2}
                                                    mobile={2}
                                                    tablet={2}
                                                    textAlign='center'>
                                                    <label className='truncate subtitle'>Indice</label>
                                                    <Input
                                                        onChange={(e) => {
                                                            setIndice(e.target.value)
                                                        }}
                                                        placeholder='Indice'
                                                        size={'large'}
                                                        value={indice}/>
                                                </Grid.Column>

                                                <Grid.Column
                                                    className='col-pad5'
                                                    computer={8}
                                                    mobile={8}
                                                    tablet={8}
                                                    textAlign='center'>
                                                    <label className='truncate subtitle'>Ingrese el nombre del
                                                        tema</label>
                                                    <Input
                                                        onChange={(e) => setTemaName(e.target.value)}
                                                        placeholder='Nombre del tema'
                                                        size={'large'}
                                                        value={temaName}/>
                                                </Grid.Column>

                                                <Grid.Column
                                                    className='col-pad5'
                                                    computer={2}
                                                    mobile={2}
                                                    tablet={2}
                                                    textAlign='center'>
                                                    <label className='truncate subtitle'> &nbsp; </label>
                                                    <Popup
                                                        content={'Aprendizajes'}
                                                        trigger={<Button
                                                            color='teal'
                                                            loading={loading}
                                                            onClick={() => {
                                                                setModalCrearAprendizajes(true);
                                                            }}
                                                            icon='caret square up outline'/>}
                                                    />
                                                </Grid.Column>


                                                <Grid.Column
                                                    className='col-pad5'
                                                    computer={2}
                                                    mobile={2}
                                                    tablet={2}
                                                    textAlign='center'>
                                                    <label className='truncate subtitle'> &nbsp; </label>
                                                    <Popup
                                                        content={'Competencias'}
                                                        trigger={<Button
                                                            color='teal'
                                                            loading={loading}
                                                            onClick={() => {
                                                                setModalCrearCompetencias(true);
                                                            }}
                                                            icon='caret square up outline'/>}
                                                    />
                                                </Grid.Column>

                                                <Grid.Column
                                                    className='col-pad5'
                                                    computer={2}
                                                    mobile={2}
                                                    tablet={2}
                                                    textAlign='center'>
                                                    <label className='truncate subtitle'> &nbsp; </label>
                                                    <Popup
                                                        content={'Guardar'}
                                                        trigger={<Button
                                                            color='teal'
                                                            loading={loading}
                                                            onClick={saveTema}
                                                            icon='save'/>}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    : null}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
}

export default PlanEstudios;
