import {COUNTRIES, LOADING_COUNTRIES,SELECTED_COUNTRY} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_COUNTRIES,
        payload: data
    };
}

export function createCountry(token, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'countries/create', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchCountries(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchCountries(token));
        });
    };
}

export function fetchCountries(token) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'countries/get', {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchCountriesSuccess(response.data));
        }).catch(error => {
            dispatch(fetchCountriesFailed());
        });
    };
}

export function updateCountry(token, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'countries/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchCountries(token));
        }).catch(error => {
            // dispatch(fetchCountriesFailed());
        });
    };
}

export function deleteCountry(token, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'countries/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchCountries(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchCountries(token));
        });
    };
}

export function fetchCountriesSuccess(response) {
    return {
        type: COUNTRIES,
        payload: response
    };
}

export function fetchCountriesFailed() {
    Functions.alertFail();
    return {
        type: COUNTRIES,
        payload: []
    };
}

export function setCountry(country) {
    return {
        type: SELECTED_COUNTRY,
        payload: country
    };
}