import {
    ALIAS_COLONIA,
    ALIAS_ESTADO,
    ALIAS_CIUDAD,
    ESTADO,
    ALIAS_ID_ESCUELA,
    APELLIDO_MATERNO,
    CODIGO_POSTAL,
    DATA_BASE,
    GLOBALS,
    ID_CIUDADANO,
    ID_ESCUELA,
    LOADING_GLOBALS
} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';
import {ALIAS_ID_CIUDADANO} from '../types';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_GLOBALS,
        payload: data
    };
}

export function fetchGlobals(token, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'globals/get/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchGlobalsSuccess(response.data));
        }).catch(error => {
            dispatch(fetchGlobalsFailed());
        });
    };
}

export function updateGlobals(token, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'globals/update', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchGlobals(token, id));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchGlobals(token, id));
        });
    };
}


export function fetchGlobalsSuccess(response) {
    return {
        type: GLOBALS,
        payload: response
    };
}

export function fetchGlobalsFailed() {
    Functions.alertFail();
    return {
        type: GLOBALS,
        payload: []
    };
}

export function setDataBase(data) {
    return {
        type: DATA_BASE,
        payload: data
    };
}

export function setApellidoMaterno(data) {
    return {
        type: APELLIDO_MATERNO,
        payload: data
    };
}

export function setIdCiudadano(data) {
    return {
        type: ID_CIUDADANO,
        payload: data
    };
}

export function setAliasIdCiudadano(data) {
    return {
        type: ALIAS_ID_CIUDADANO,
        payload: data
    };
}

export function setIdEscuela(data) {
    return {
        type: ID_ESCUELA,
        payload: data
    };
}

export function setAliasIdEscuela(data) {
    return {
        type: ALIAS_ID_ESCUELA,
        payload: data
    };
}

export function setCodigoPostal(data) {
    return {
        type: CODIGO_POSTAL,
        payload: data
    };
}

export function setAliasColonia(data) {
    return {
        type: ALIAS_COLONIA,
        payload: data
    };
}

export function setAliasCiudad(data) {
    return {
        type: ALIAS_CIUDAD,
        payload: data
    };
}

export function setEstado(data) {
    return {
        type: ESTADO,
        payload: data
    };
}

export function setAliasEstado(data) {
    return {
        type: ALIAS_ESTADO,
        payload: data
    };
}
