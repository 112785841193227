import {
    DELETE_TIPO_MATERIA,
    LOADING_TIPOS_MATERIAS,
    SELECTED_TIPO_MATERIA,
    TIPOS_MATERIAS,
    UPDATE_TIPO_MATERIA
} from '../../actions/types';

const initialState = {
        loading: false,
        tipo_materia: 1,
        tipos_materias: []
    }
;

export default function NivelesReducer(state = initialState, action) {

    switch (action.type) {
        case TIPOS_MATERIAS:
            return {
                ...state,
                tipos_materias: action.payload,
                loading: false
            };
        case DELETE_TIPO_MATERIA:
            return {
                ...state,
                tipos_materias: action.payload,
                loading: false
            };
        case UPDATE_TIPO_MATERIA:
            return {
                ...state,
                tipos_materias: action.payload,
                loading: false
            };
        case SELECTED_TIPO_MATERIA:
            return {
                ...state,
                tipo_materia: action.payload
            };
        case LOADING_TIPOS_MATERIAS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
