import {LOADING_TYPE_EVENTS, SELECTED_COLOR, SELECTED_TYPE_EVENT, TYPE_EVENTS} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_TYPE_EVENTS,
        payload: data
    };
}

export function createTypeEvent(token, country, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'type/events/create', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTypeEvents(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTypeEvents(token, country));
        });
    };
}

export function fetchTypeEvents(token, country) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'type/events/get/' + country, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchTypeEventsSuccess(response.data));
        }).catch(error => {
            dispatch(fetchTypeEventsFailed());
        });
    };
}

export function updateTypeEvent(token, country, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'type/events/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTypeEvents(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTypeEvents(token, country));
        });
    };
}

export function deleteTypeEvent(token, country, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'type/events/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTypeEvents(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTypeEvents(token, country));
        });
    };
}

export function fetchTypeEventsSuccess(response) {
    return {
        type: TYPE_EVENTS,
        payload: response
    };
}

export function fetchTypeEventsFailed() {
    Functions.alertFail();
    return {
        type: TYPE_EVENTS,
        payload: []
    };
}

export function setTypeEvent(TypeEvent) {
    return {
        type: SELECTED_TYPE_EVENT,
        payload: TypeEvent
    };
}

export function setColor(color) {
    return {
        type: SELECTED_COLOR,
        payload: color
    };
}
