import {ALIAS, DELETE_ALIAS, LOADING_ALIAS, SELECTED_ALIAS, UPDATE_ALIAS} from '../../actions/types';

const initialState = {
        loading: false,
        aliass: {
            id: 1,
            code: 'MX',
            nombre: 'MEXICO',
            bandera: 'mx'
        },
        alias: []
    }
;

export default function NivelesReducer(state = initialState, action) {

    switch (action.type) {
        case ALIAS:
            return {
                ...state,
                alias: action.payload,
                loading: false
            };
        case DELETE_ALIAS:
            return {
                ...state,
                alias: action.payload,
                loading: false
            };
        case UPDATE_ALIAS:
            return {
                ...state,
                alias: action.payload,
                loading: false
            };
        case SELECTED_ALIAS:
            return {
                ...state,
                aliass: action.payload
            };
        case LOADING_ALIAS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}