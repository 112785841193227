import {CICLOS, LOADING_CICLOS, SELECTED_CICLO} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_CICLOS,
        payload: data
    };
}

export function createCiclo(token, country,nivel, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'ciclos/create', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchCiclos(token, country,nivel));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchCiclos(token, country,nivel));
        });
    };
}

export function fetchCiclos(token, country, nivel) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'ciclos/get/' + country + '/' + nivel, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchCiclosSuccess(response.data));
        }).catch(error => {
            dispatch(fetchCiclosFailed());
        });
    };
}

export function updateCiclo(token, country,nivel, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'ciclos/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchCiclos(token, country,nivel));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchCiclos(token, country,nivel));
        });
    };
}

export function deleteCiclo(token, country,nivel, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'ciclos/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchCiclos(token, country,nivel));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchCiclos(token, country,nivel));
        });
    };
}

export function fetchCiclosSuccess(response) {
    return {
        type: CICLOS,
        payload: response
    };
}

export function fetchCiclosFailed() {
    Functions.alertFail();
    return {
        type: CICLOS,
        payload: []
    };
}

export function setCiclo(Ciclo) {
    return {
        type: SELECTED_CICLO,
        payload: Ciclo
    };
}