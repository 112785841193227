import React, {useState} from 'react';
import {Button, Divider, Grid, Header, Image, Input, Label} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {login} from '../../redux/actions/index';
import {useDispatch, useSelector} from 'react-redux';
import Functions from '../Global/Functions';
import {Redirect} from 'react-router-dom';


function Landing() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [aux, setAux] = useState('');
    const loading = useSelector(state => state.root.loading);
    const token = useSelector(state => state.root.token);
    const userId = useSelector(state => state.root.userId);
    const userRole = useSelector(state => state.root.userRole);
    const userName = useSelector(state => state.root.userName);
    const userPicture = useSelector(state => state.root.userPicture);
    const marcas = useSelector(state => state.root.marcas);
    const marca = useSelector(state => state.root.marca);
    const marcas2 = [{
        name: 'Kolegia School Manager',
        page: 'https://kolegia.com',
        imagen: 'https://kolegia.com/images/logo.png',
        facebook: 'KolegiaLATAM',
        whatsApp: '5212282205275',
        twitter: 'KolegiaLatam',
        email: 'contacto@kolegia.com'
    }, {
        name: '',
        imagen: 'https://galeriasce.s3-us-west-2.amazonaws.com/img-repos/ZKL6VF3425ZCQK/logo_preinscripcion.png',
        facebook: 'ControlEscolarMX/',
        whatsApp: '5212282205275',
        twitter: '_controlescolar',
        email: 'contacto@controlescolar.pro'
    }, {
        name: 'Control Escolar',
        page: 'https://controlescolar.pro',
        imagen: 'https://controlescolar.pro/images/logo.png',
        facebook: 'ControlEscolarMX/',
        whatsApp: '5212282205275',
        twitter: '_controlescolar',
        email: 'contacto@controlescolar.pro'
    }]

    const renderMarcas = marcas.map(it => (
        {key: it.id, value: it.id, text: it.name, url: it.url}
    ));

    const renderMarcas2 = marcas2.map(it => {
            if (it.name === '') {
                return <Grid.Column
                    className='borderRadius '
                    computer={2}
                    mobile={2}
                    tablet={2}
                    textAlign='center'> </Grid.Column>
            } else {
                return <Grid.Column
                    className='borderRadius bg-white'
                    computer={6}
                    mobile={14}
                    tablet={6}
                    style={{padding: 100, paddingBottom: 200, paddingTop: 200}}
                    textAlign='center'>
                    <Label style={{backgroundColor: '#f1f1f1'}} attached='top'>
                        <Header as='h2'>{it.name}</Header>
                    </Label>
                    <Label style={{backgroundColor: '#f1f1f1'}} attached='bottom'>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                style={{
                                    margin: 0,
                                    padding: 0
                                }}
                                className="centerAll ">
                                <Grid.Column
                                    className='borderRadius'
                                    computer={4}
                                    mobile={4}
                                    tablet={4}
                                    textAlign='center'
                                    onClick={() => window.open('https://twitter.com/' + it.twitter, '_blank')}>
                                    <i className="tw-hover fab fa-twitter fa-2x Color4"/>
                                </Grid.Column>
                                <Grid.Column
                                    className='borderRadius'
                                    computer={4}
                                    mobile={4}
                                    tablet={4}
                                    textAlign='center'
                                    onClick={() => window.open('https://www.facebook.com/' + it.facebook, '_blank')}>
                                    <i className="fb-hover fab fa-facebook-f fa-2x Color4"/>
                                </Grid.Column>
                                <Grid.Column
                                    className='borderRadius'
                                    computer={4}
                                    mobile={4}
                                    tablet={4}
                                    textAlign='center'
                                    onClick={() => window.open('mailto:' + it.email, '_blank')}>
                                    <i className="mail-hover far fa-envelope fa-2x Color4"/>
                                </Grid.Column>

                                <Grid.Column
                                    className='borderRadius'
                                    computer={4}
                                    mobile={4}
                                    tablet={4}
                                    textAlign='center'
                                    onClick={() => window.open('https://wa.me/' + it.whatsApp, '_blank')}>
                                    <i className="wa-hover fab fa-whatsapp fa-2x Color4"/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Label>
                    <Image src={it.imagen} onClick={() => window.open(it.page, '_blank')} fluid centered/>
                </Grid.Column>
            }
        }
    );
    const dispatch = useDispatch();
    const logIn = () => {
        if (!loading) {
            if (email === '' || password === '') {
                let text = 'Verifique que los campos email y contraseña no estén vacíos';
                Functions.alertWarning(text);
            } else {
                let fd = JSON.stringify({email: email, password: password});
                dispatch(login(fd));
            }

        }
    };

    if (window.location.host === 'grupoeducere.com') {
        return (
            <Grid
                columns={3}
                stretched
                style={{height: '100vh', maxHeight: '100vh', backgroundColor: '#fff'}}
                className='no-margin'>
                <Grid.Row
                    centered
                    className='no-margin'
                    stretched
                    style={{padding: 50}}
                    verticalAlign='middle'>
                    {renderMarcas2}
                </Grid.Row>
            </Grid>
        );
    } else if (token !== '' && token !== undefined) {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('userRole', userRole);
        sessionStorage.setItem('userName', userName);
        sessionStorage.setItem('userPicture', userPicture);
        return <Redirect to={'/dash-board'}/>;
    } else {
        return (
            <Grid stretched style={{height: '100vh', maxHeight: '100vh'}}
                        className='no-margin'>
                <Grid.Row
                    centered
                    className='no-margin'
                    stretched
                    style={{padding: 50}}
                    verticalAlign='middle'>
                    <Grid.Column
                        className='borderRadius'
                        color='blue'
                        computer={6}
                        mobile={16}
                        tablet={12}
                        textAlign='center'>
                        <Image src='/images/logo.png' centered size='medium' circular/>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    className='borderRadius'
                                    color='blue'
                                    computer={14}
                                    mobile={14}
                                    tablet={14}
                                    textAlign='center'>
                                    {/*<Select*/}
                                    {/*    onChange={(e, data) =>*/}
                                    {/*        dispatch(setMarca(marcas.find(item => item.id === data.value)))}*/}
                                    {/*    options={renderMarcas}*/}
                                    {/*    placeholder='Selecciona el nivel'*/}
                                    {/*    value={marca.id}/>*/}
                                    {/*<Divider className='divider-5' hidden/>*/}
                                    <Input
                                        color='teal'
                                        fluid
                                        label={{icon: 'at'}}
                                        labelPosition='left corner'
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder='Email'
                                        value={email}
                                    />
                                    <Divider className='divider-5' hidden/>
                                    <Input
                                        color='teal'
                                        fluid
                                        type='password'
                                        label={{icon: 'lock'}}
                                        labelPosition='right corner'
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder='Contraseña'
                                        value={password}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    className='borderRadius'
                                    color='blue'
                                    computer={12}
                                    mobile={12}
                                    tablet={12}
                                    textAlign='center'>
                                    <Button
                                        fluid
                                        color='teal'
                                        loading={loading}
                                        onClick={() => logIn(email, password)}
                                        content='Ingresar'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default Landing;
