import {CALENDARIOS, LOADING_CALENDARIOS, SELECTED_CALENDARIO} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_CALENDARIOS,
        payload: data
    };
}

export function createCalendario(token, country, nivel, tipoNivel, ciclo, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'calendarios/create', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchCalendarios(token, country, nivel, tipoNivel, ciclo));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchCalendarios(token, country, nivel, tipoNivel, ciclo));
        });
    };
}

export function fetchCalendarios(token, country, nivel, tipoNivel, ciclo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'calendarios/get/' + country + '/' + nivel + '/' + tipoNivel + '/' + ciclo, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchCalendariosSuccess(response.data));
        }).catch(error => {
            dispatch(fetchCalendariosFailed());
        });
    };
}

export function updateCalendario(token, country, nivel, tipoNivel, ciclo, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'calendarios/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchCalendarios(token, country, nivel, tipoNivel, ciclo));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchCalendarios(token, country, nivel, tipoNivel, ciclo));
        });
    };
}

export function deleteCalendario(token, country, nivel, tipoNivel, ciclo, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'calendarios/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchCalendarios(token, country, nivel, tipoNivel, ciclo));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchCalendarios(token, country, nivel, tipoNivel, ciclo));
        });
    };
}

export function fetchCalendariosSuccess(response) {
    return {
        type: CALENDARIOS,
        payload: response
    };
}

export function fetchCalendariosFailed() {
    Functions.alertFail();
    return {
        type: CALENDARIOS,
        payload: []
    };
}

export function setCalendario(Calendario) {
    return {
        type: SELECTED_CALENDARIO,
        payload: Calendario
    };
}
