import {LOADING, LOGIN, LOGOUT, SET_MARCA} from '../actions/types';

const initialState = {
    loading: false,
    marcas: [
        {
            id: 1,
            name: 'Control Escolar',
            url: 'https://controlescolar.pro',
            bd: ''
        },
        {
            id: 2,
            name: 'Kolegia School Manager',
            url: 'https://kolegia.com',
            bd: ''
        },
        {
            id: 2,
            name: 'iCode Studio',
            url: 'https://icodestudio.pro',
            bd: ''
        },
        {
            id: 3,
            name: 'Aprentia',
            url: 'https://aprentia.com',
            bd: ''
        }
    ],
    marca: {
        id: sessionStorage.getItem('mid') || '',
        name: sessionStorage.getItem('brand') || '',
        url: sessionStorage.getItem('url') || '',
        db: sessionStorage.getItem('db') || ''
    },
    token: sessionStorage.getItem('token') || '',
    userId: sessionStorage.getItem('userId') || '',
    userName: sessionStorage.getItem('userName') || '',
    userPicture: sessionStorage.getItem('userPicture') || 'https://react.semantic-ui.com/images/avatar/small/veronika.jpg',
    userRole: sessionStorage.getItem('userRole') || ''
};

export default function rootReducer(state = initialState, action) {

    switch (action.type) {
        case LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case LOGIN:
            return {
                ...state,
                token: action.payload.token,
                userId: action.payload.id,
                userRole: action.payload.role,
                userName: action.payload.name,
                loading: false
            };
        case LOGOUT:
            return {
                ...state,
                token: action.payload.token,
                userId: action.payload.id,
                userRole: action.payload.role,
                userName: action.payload.name,
                loading: false
            };
        case SET_MARCA:
            return {
                ...state,
                marca: action.payload,
                loading: false
            };
        default:
            return {
                ...state,
                loading: action.payload
            };
    }
}
