import {combineReducers} from 'redux';
import alias from './CountriesReducers/AliasReducer';
import calendarios from './CountriesReducers/CalendariosReducer';
import ciclos from './CountriesReducers/CiclosReducer';
import countries from './CountriesReducers/countriesReducer';
import grados from './CountriesReducers/GradosReducer';
import materias from './CountriesReducers/MateriasReducer';
import menu from './menuReducer';
import niveles from './CountriesReducers/NivelesReducer';
import partners from './PartnersReducers/PartnersReducer';
import root from './rootReducer';
import TipoNiveles from './CountriesReducers/TiposNivelesReducer';
import TypeEvents from './CountriesReducers/TypeEventsReducer';
import Globals from './CountriesReducers/GlobalsReducer';
import RolesReducer from './RolesReducers/RolesReducer';
import PermissionsReducer from './RolesReducers/PermissionsReducer';
import TiposMaterias from './CountriesReducers/TipoMateriaReducer';
import TiposCalendarios from './CountriesReducers/TipoCalendarioReducer';
import PlanEstudioReducer from './CountriesReducers/PlanEstudioReducer';
import AvisosReducer from './AvisosReducers/AvisosReducer';

export default combineReducers({
    alias: alias,
    calendarios: calendarios,
    ciclos: ciclos,
    countries: countries,
    grados: grados,
    materias: materias,
    menu: menu,
    niveles: niveles,
    partners: partners,
    root: root,
    tipo_niveles: TipoNiveles,
    type_events: TypeEvents,
    globals: Globals,
    roles: RolesReducer,
    permissions: PermissionsReducer,
    tipos_materias: TiposMaterias,
    tipos_calendarios: TiposCalendarios,
    plan_estudios: PlanEstudioReducer,
    avisos: AvisosReducer
});
