import React, {useEffect, useState} from 'react';
import {
    Button,
    Confirm,
    Divider,
    Flag,
    Grid,
    Header,
    Icon,
    Input,
    Item,
    Modal,
        Select
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {createGrado, deleteGrado, fetchGrados, updateGrado} from '../../redux/actions/CountriesActions/GradosActions';
import Functions from '../Global/Functions';
import {fetchNiveles, setNivel} from '../../redux/actions/CountriesActions/NivelesActions';
import {fetchCiclos, setCiclo} from '../../redux/actions/CountriesActions/CiclosActions';
import {fetchTipoNiveles, setTipoNivel} from '../../redux/actions/CountriesActions/TipoNivelesActions';

function Grados() {
    const [nombre, setNombre] = useState('');
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selected, setSelected] = useState('');
    const [modal, setModal] = useState(false);
    const grados = useSelector(state => state.grados.grados);
    const loading = useSelector(state => state.grados.loading);
    const country = useSelector(state => state.countries.country);
    const niveles = useSelector(state => state.niveles.niveles);
    const nivel = useSelector(state => state.niveles.nivel);
    const tipoNivel = useSelector(state => state.tipo_niveles.tipo_nivel);
    const tipoNiveles = useSelector(state => state.tipo_niveles.tipo_niveles);
    const ciclo = useSelector(state => state.ciclos.ciclo);
    const ciclos = useSelector(state => state.ciclos.ciclos);
    const token = useSelector(state => state.root.token);
    const dispatch = useDispatch();
    const renderNiveles = niveles.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const renderCiclos = ciclos.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const renderTipoNiveles = tipoNiveles.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const save = () => {
        if (nombre) {
            let fd = JSON.stringify({
                id_pais: country.id,
                id_nivel: nivel,
                tipo_nivel: tipoNivel,
                id_ciclo: ciclo,
                nombre: nombre.toUpperCase()
            });
            dispatch(createGrado(token, country.id, nivel, tipoNivel, ciclo, fd));
            setNombre('');
        } else {
            Functions.alertWarning();
        }
    };

    const edit = () => {
        if (nombre) {
            let fd = JSON.stringify({
                id_pais: country.id,
                id_nivel: nivel,
                tipo_nivel: tipoNivel,
                id_ciclo: ciclo,
                nombre: nombre.toUpperCase()
            });
            dispatch(updateGrado(token, country.id, nivel, tipoNivel, ciclo, fd, selected.id));
            setSelected('');
            setNombre('');
            setModal(false);
        } else {
            Functions.alertWarning();
        }
    };

    const gradosRendered = grados.map(it =>
        (<Item key={it.id}>
            <Flag name={it.bandera}/>
            <Item.Content verticalAlign='middle'>
                <Item.Header>Grado: {it.nombre}</Item.Header>
                <Item.Meta>{it.nivel.nombre}</Item.Meta>
                <Item.Extra floated='right'>
                    <Button size='mini' content='Borrar' icon='delete' labelPosition='left' onClick={() => {
                        setSelected(it.id);
                        setConfirmDelete(true);
                    }}/>
                    <Button
                        size='mini'
                        content='Editar'
                        icon='edit'
                        labelPosition='right'
                        onClick={() => {
                            setSelected(it);
                            dispatch(setNivel(Number(it.id_nivel)));
                            setNombre(it.nombre);
                            setModal(true);
                        }}/>
                </Item.Extra>
            </Item.Content>
        </Item>)
    );

    useEffect(() => {
        async function getNiveles() {
            await dispatch(fetchNiveles(token, country.id));
        }

        getNiveles();
    }, [country]);

    useEffect(() => {
        async function getTipoNiveles() {
            await dispatch(fetchTipoNiveles(token, country.id, nivel));
        }

        getTipoNiveles();
    }, [nivel]);

    useEffect(() => {
        async function getCiclos() {
            await dispatch(fetchCiclos(token, country.id, nivel));
        }

        getCiclos();
    }, [nivel]);

    useEffect(() => {
        async function getGrados() {
            await dispatch(fetchGrados(token, country.id, nivel, tipoNivel, ciclo));
        }

        getGrados();
    }, [ciclo, tipoNivel]);

    return (
        <Grid className='no-margin' style={{width: '100%'}}>
            {/*<=<●>=><=<●>=><=<●>=> Confirmar Borrado <=<●>=><=<●>=><=<●>=>*/}
            <Confirm
                open={confirmDelete}
                content='¿Está seguro que quiere eliminar este grado?'
                cancelButton={<Button color='red' icon='close' content='Cancelar' inverted/>}
                confirmButton={<Button color='green' icon='checkmark' content='Si, eliminar'/>}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={() => [dispatch(deleteGrado(token, country.id, nivel, ciclo, selected)), setConfirmDelete(false)]}
            />

            <Modal size='tiny' open={modal}>
                <Modal.Header>Editando: {selected.nombre}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    <label className='truncate subtitle'>Seleccione un nivel</label>
                                    <Select
                                        value={nivel}
                                        onChange={(e, data) => dispatch(setNivel(data.value))}
                                        placeholder='Selecciona el nivel'
                                        options={renderNiveles}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione un tipo nivel</label>
                                    <Select
                                        value={tipoNivel}
                                        onChange={(e, data) => dispatch(setTipoNivel(data.value))}
                                        placeholder='Selecciona el tipo de nivel'
                                        options={renderTipoNiveles}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Ingrese el nombre</label>
                                    <Input
                                        fluid
                                        onChange={(e) => setNombre(e.target.value)}
                                        placeholder='Nombre del grado'
                                        size={'large'}
                                        className='uppercase'
                                        value={nombre}/>
                                    <Divider className='divider-5' hidden/>
                                    <Button
                                        fluid
                                        color='teal'
                                        loading={loading}
                                        onClick={edit}
                                        content='Guardar'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModal(false);
                            setSelected('');
                            setNombre('');
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Grid.Row
                stretched
                column={2}
                verticalAlign='top'
                className='no-margin'>
                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Crear grado</Header>
                    <Grid centered className='no-margin'>
                        <Grid.Row
                            centered
                            className='no-margin scroll1'
                            stretched
                            verticalAlign='middle'>
                            <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                                textAlign='center'>
                                <label className='truncate subtitle'>Seleccione un nivel</label>
                                <Select
                                    value={nivel}
                                    onChange={(e, data) => dispatch(setNivel(data.value))}
                                    placeholder='Selecciona el nivel'
                                    options={renderNiveles}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un tipo nivel</label>
                                <Select
                                    value={tipoNivel}
                                    onChange={(e, data) => dispatch(setTipoNivel(data.value))}
                                    placeholder='Selecciona el tipo de nivel'
                                    options={renderTipoNiveles}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un ciclo</label>
                                <Select
                                    value={ciclo}
                                    onChange={(e, data) => dispatch(setCiclo(data.value))}
                                    placeholder='Selecciona el ciclo'
                                    options={renderCiclos}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Ingrese un nombre</label>
                                <Input
                                    fluid
                                    onChange={(e) => setNombre(e.target.value)}
                                    placeholder='Nombre del grado'
                                    size={'large'}
                                    style={{textTransform: 'uppercase'}}
                                    value={nombre}/>
                                <Divider className='divider-5' hidden/>
                                <Button
                                    fluid
                                    color='teal'
                                    loading={loading}
                                    onClick={save}
                                    content='Guardar'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>

                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Consultar grados</Header>
                    <Grid.Row
                        stretched
                        className='no-margin scroll1'
                        style={{backgroundColor: '#fff', padding: 10, borderRadius: 10}}>
                        <Item.Group divided relaxed style={{width: '100%'}}>
                            {grados.length !== 0
                                ? loading
                                    ? Functions.loadingMessage()
                                    : gradosRendered
                                : loading
                                    ? Functions.loadingMessage()
                                    : Functions.nothingMessage()}
                        </Item.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
}

export default Grados;
