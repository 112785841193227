import {TIPOS_NIVELES, DELETE_TIPOS_NIVEL, LOADING_TIPOS_NIVELES, SELECTED_TIPOS_NIVEL, UPDATE_TIPOS_NIVEL} from '../../actions/types';

const initialState = {
        loading: false,
        tipo_nivel: 1,
        tipo_niveles: []
    }
;

export default function NivelesReducer(state = initialState, action) {

    switch (action.type) {
        case TIPOS_NIVELES:
            return {
                ...state,
                tipo_niveles: action.payload,
                loading: false
            };
        case DELETE_TIPOS_NIVEL:
            return {
                ...state,
                tipo_niveles: action.payload,
                loading: false
            };
        case UPDATE_TIPOS_NIVEL:
            return {
                ...state,
                tipo_niveles: action.payload,
                loading: false
            };
        case SELECTED_TIPOS_NIVEL:
            return {
                ...state,
                tipo_nivel: action.payload
            };
        case LOADING_TIPOS_NIVELES:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}