import React, {useEffect, useState} from 'react';
import {Button, Card, Confirm, Grid, Header, Icon, Image, Modal, Pagination} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {acceptPartner, declinePartner, fetchPartners} from '../../redux/actions/PartnersActions/PartnersActions';

function Partners() {
    const partners = useSelector(state => state.partners.partners);
    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState(false);
    const [selected, setSelected] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [confirmAccept, setConfirmAccept] = useState(false);
    const [confirmDecline, setConfirmDecline] = useState(false);
    const token = useSelector(state => state.root.token);
    const dispatch = useDispatch();
    const partnersRendered = partners.map(it =>
        (<Card key={it.id} color={it.status === '0' ? 'gray' : it.status === '1' ? 'green' : 'red'}>
            <Card.Content>
                <Card.Header>{it.nombres + ' ' + it.apellido_paterno + ' ' + it.apellido_materno}</Card.Header>
                <Card.Meta>Id asignado: {it.partner}</Card.Meta>
                <Card.Description>
                    <strong>Email: </strong>{it.email}
                    <br/>
                    <strong>Celular: </strong>{it.celular}
                    <br/>
                    <strong>Dirección: </strong>{it.calle + ' ' + it.numero + ' ' + it.colonia + ', ' + it.ciudad + ' ' + it.estado}
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <div className='ui three buttons'>
                    {it.status !== '1'
                        ? <Button basic color='green' onClick={() => [setSelected(it), setConfirmAccept(true)]}>
                            Aprobar
                        </Button>
                        : null}
                    <Button onClick={() => [setSelected(it), setModal(true)]} basic color='black'>
                        Docs.
                    </Button>
                    {it.status !== '2'
                        ? <Button basic color='red' onClick={() => [setSelected(it), setConfirmDecline(true)]}>
                            Declinar
                        </Button>
                        : null}
                </div>
            </Card.Content>
        </Card>)
    );

    useEffect(() => {
        async function asd() {
            await dispatch(fetchPartners(token));
        }

        asd();
    }, []);

    return (
        <Grid className='no-margin' style={{width: '100%'}}>

            {/*<=<●>=><=<●>=><=<●>=> Modal <=<●>=><=<●>=><=<●>=>*/}
            <Modal dimmer={'blurring'} open={modal} onClose={() => setModal(false)}>
                <Modal.Header>Información del partner</Modal.Header>
                <Modal.Content image>
                    <Card>
                        <Card.Content
                            header={activePage === 1 ? 'ID Frontal' : activePage === 2 ? 'ID Posteriror' : 'Domicilio'}/>
                        <Card.Content>
                            <Image
                                onClick={() => setModalImage(true)}
                                floated='right'
                                wrapped
                                size='medium'
                                src={activePage === 1 ? selected.id_front : activePage === 2 ? selected.id_back : selected.domicilio}
                            />
                            <Card.Description textAlign={'center'}>
                                <Pagination
                                    pointing
                                    secondary
                                    activePage={activePage}
                                    boundaryRange={0}
                                    ellipsisItem={null}
                                    firstItem={null}
                                    lastItem={null}
                                    siblingRange={1}
                                    totalPages={3}
                                    onPageChange={(e, p) => setActivePage(p.activePage)}
                                />
                            </Card.Description>
                        </Card.Content>
                    </Card>
                    <Modal.Description style={{padding: 10}}>
                        <Header>{selected.nombres + ' ' + selected.apellido_paterno + ' ' + selected.apellido_materno}</Header>
                        <strong>CURP: </strong>{selected.id_personal}
                        <br/>
                        <strong>Email: </strong>{selected.email}
                        <br/>
                        <strong>Celular: </strong>{selected.celular}
                        <br/>
                        <strong>Teléfono de emergencia: </strong>{selected.emergencia}
                        <br/>
                        <strong>Dirección: </strong>{selected.calle + ' ' + selected.numero + ' ' + selected.interior}
                        <br/>
                        {selected.colonia + ', ' + selected.ciudad + ' ' + selected.estado}
                        <br/>
                        <Header as='h3'>Datos de la referencia:</Header>
                        <strong>Nombre: </strong>{selected.nombre_ref}
                        <br/>
                        <strong>Teléfono: </strong>{selected.telefono_ref}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => setModal(false)} inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            {/*<=<●>=><=<●>=><=<●>=> Confirmar Aceptado <=<●>=><=<●>=><=<●>=>*/}
            <Confirm
                open={confirmAccept}
                content='¿Está seguro que quiere aceptar esta solicitud?'
                cancelButton={<Button color='red' icon='close' content='Cancelar' inverted/>}
                confirmButton={<Button color='green' icon='checkmark' content='Si, Continuar'/>}
                onCancel={() => setConfirmAccept(false)}
                onConfirm={() => [dispatch(acceptPartner(token, selected.partner)), setConfirmAccept(false)]}
            />

            {/*<=<●>=><=<●>=><=<●>=> Confirmar Borrado <=<●>=><=<●>=><=<●>=>*/}
            <Confirm
                open={confirmDecline}
                content='¿Está seguro que quiere declinar esta solicitud?'
                cancelButton={<Button color='red' icon='close' content='Cancelar' inverted/>}
                confirmButton={<Button color='green' icon='checkmark' content='Si, Continuar'/>}
                onCancel={() => setConfirmDecline(false)}
                onConfirm={() => [dispatch(declinePartner(token, selected.partner)), setConfirmDecline(false)]}
            />

            {/*<=<●>=><=<●>=><=<●>=> Modal per image <=<●>=><=<●>=><=<●>=>*/}
            <Modal open={modalImage} onClose={() => setModalImage(false)} size='small'>
                <Modal.Content centered style={{alignItems:'center', justifyContent:'center'}}>
                    <Image
                        src={activePage === 1 ? selected.id_front : activePage === 2 ? selected.id_back : selected.domicilio}/>
                    <Pagination
                        pointing
                        secondary
                        activePage={activePage}
                        boundaryRange={0}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        totalPages={3}
                        onPageChange={(e, p) => setActivePage(p.activePage)}
                    />
                </Modal.Content>


                <Modal.Actions>
                    <Button onClick={() => setModalImage(false)} color='red' inverted>
                        <Icon name='remove'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            {/*<=<●>=><=<●>=><=<●>=> Render <=<●>=><=<●>=><=<●>=>*/}
            <Grid.Row
                stretched
                column={1}
                className='no-margin'>
                <Grid.Column
                    computer={16}
                    mobile={16}
                    tablet={16}
                    textAlign='center border-radius'>
                    <Header as='h2'>Verificación de partners</Header>
                    <Grid.Row
                        stretched
                        className='no-margin'
                        style={{backgroundColor: '#fff', padding: 10, borderRadius: 10}}>
                        <Card.Group>
                            {partnersRendered}
                        </Card.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
}

export default Partners;
