import {LOADING_MATERIAS, MATERIAS, SELECTED_MATERIA} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_MATERIAS,
        payload: data
    };
}

export function createMateria(token, country, nivel, ciclo, tipoNivel, grado, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'subjects/create', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchMaterias(token, country, nivel, ciclo, tipoNivel, grado));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchMaterias(token, country, nivel, ciclo, tipoNivel, grado));
        });
    };
}

export function fetchMaterias(token, country, nivel, ciclo, tipoNivel, grado) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'subjects/get/' + country + '/' + nivel + '/' + grado+ '/' + ciclo + '/' + tipoNivel, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchMateriasSuccess(response.data));
        }).catch(error => {
            dispatch(fetchMateriasFailed());
        });
    };
}

export function updateMateria(token, country, nivel, grado, ciclo, tipoNivel, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'subjects/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchMaterias(token, country, nivel, grado, ciclo, tipoNivel));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchMaterias(token, country, nivel, grado, ciclo, tipoNivel));
        });
    };
}

export function deleteMateria(token, country, nivel, grado, ciclo, tipoNivel, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'subjects/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchMaterias(token, country, nivel, ciclo, tipoNivel, grado));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchMaterias(token, country, nivel, ciclo, tipoNivel, grado));
        });
    };
}

export function fetchMateriasSuccess(response) {
    return {
        type: MATERIAS,
        payload: response
    };
}

export function fetchMateriasFailed() {
    Functions.alertFail();
    return {
        type: MATERIAS,
        payload: []
    };
}

export function setMateria(Materia) {
    return {
        type: SELECTED_MATERIA,
        payload: Materia
    };
}