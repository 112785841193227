import {AVISOS_DESTINATARIOS, GET_AVISOS, LOADING_AVISOS} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_AVISOS,
        payload: data
    };
}

export function createAvisos(token, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'avisos/save', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchAvisos(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchAvisos(token));
        });
    };
}

export function fetchAvisos(token) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'avisos/get', {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchAvisosSuccess(response.data));
        }).catch(error => {
            dispatch(fetchAvisosFailed());
        });
    };
}

export function fetchDestinatarios(token, tipo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'avisos/get/destinatarios/' + tipo, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchDestinatariosSuccess(response.data));
        }).catch(error => {
            dispatch(fetchDestinatariosFailed());
        });
    };
}

export function updateAvisos(token, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'avisos/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess()
            dispatch(fetchAvisos(token));
        }).catch(error => {
            Functions.alertFail()
        });
    };
}

export function deleteAvisos(token, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'avisos/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchAvisos(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchAvisos(token));
        });
    };
}

export function fetchAvisosSuccess(response) {
    return {
        type: GET_AVISOS,
        payload: response
    };
}

export function fetchAvisosFailed() {
    Functions.alertFail();
    return {
        type: GET_AVISOS,
        payload: []
    };
}

export function fetchDestinatariosSuccess(response) {
    return {
        type: AVISOS_DESTINATARIOS,
        payload: response
    };
}

export function fetchDestinatariosFailed() {
    Functions.alertFail();
    return {
        type: AVISOS_DESTINATARIOS,
        payload: []
    };
}

// export function setAlias(Alias) {
//     return {
//         type: SELECTED_ALIAS,
//         payload: Alias
//     };
// }