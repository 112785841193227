import React from 'react';
import {Divider, Grid, Icon, Image} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {goHome, logOut, setLoading} from '../../redux/actions/index';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import Menu from './Menu';
import UserInformation from './UserInformation';
import DisplayMenu from './DisplayMenu';

export default function DashBoard() {
    const token = useSelector(state => state.root.token);
    const dispatch = useDispatch();

    if (token !== '' && token !== undefined) {
        return <Grid style={{height: '100vh', maxHeight: '100vh'}} className='no-margin background2'>
            <Grid.Row
                className='no-margin'
                column={3}
                style={{padding: 0}}>

                <Grid.Column
                    only='tablet computer'
                    className='borderRadius'
                    computer={3}
                    style={{padding: 10}}
                    tablet={4}
                    textAlign='center'>
                    <Grid.Column
                        className='borderRadius sticky'
                        computer={3}
                        mobile={4}
                        style={{backgroundColor: 'rgba(255,255,255,.90)', padding: 10}}
                        tablet={4}
                        textAlign='center'>
                        <Image src='/images/logo.png' width='small' rounded
                               wrapped/>
                        <Divider className='divider-5'/>
                        <Grid className='no-margin'>
                            <Grid.Row
                                column={2}
                                className='no-margin'
                                style={{padding: 5}}>
                                <Grid.Column
                                    className='borderRadius'
                                    computer={5}
                                    mobile={5}
                                    tablet={5}
                                    textAlign='center'>
                                    <Icon
                                        name='log out'
                                        onClick={() => {
                                            dispatch(setLoading(true));
                                            dispatch(logOut());
                                        }}/>
                                </Grid.Column>
                                <Grid.Column
                                    className='borderRadius'
                                    computer={6}
                                    mobile={6}
                                    tablet={6}
                                    textAlign='center'>
                                    <Icon
                                        name='home'
                                        onClick={() => dispatch(goHome())}
                                    />
                                </Grid.Column>
                                <Grid.Column
                                    className='borderRadius'
                                    computer={5}
                                    mobile={5}
                                    tablet={5}
                                    textAlign='center'>
                                    <Icon name='key'/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider className='divider-5'/>
                        <Menu/>
                    </Grid.Column>
                </Grid.Column>


                <Grid.Column
                    className='borderRadius'
                    computer={10}
                    mobile={16}
                    tablet={12}
                    style={{padding: 10}}
                    textAlign='center'>
                    <Grid.Column
                        className='borderRadius'
                        computer={3}
                        mobile={4}
                        style={{backgroundColor: 'rgba(255,255,255,.90)', padding: 10}}
                        tablet={4}
                        textAlign='center'>
                        <DisplayMenu/>
                    </Grid.Column>

                </Grid.Column>


                <Grid.Column
                    only='computer'
                    className='borderRadius'
                    computer={3}
                    style={{padding: 10}}
                    textAlign='center'>
                    <Grid.Column
                        className='borderRadius sticky'
                        computer={3}
                        mobile={4}
                        style={{backgroundColor: 'rgba(255,255,255,.90)', padding: 10}}
                        tablet={4}
                        textAlign='center'>
                        <UserInformation/>

                    </Grid.Column>

                </Grid.Column>

            </Grid.Row>
        </Grid>;

    } else {
        return <Redirect to={'/'}/>;
    }
}
