// Dependencies
import React from 'react';
import {Route, Switch} from 'react-router-dom';
// Components
import App from './components/App';
import Page404 from './components/Page404';
import Landing from './components/Home/Landing.js';
import DashBoard from './components/Home/DashBoard.js';

const AppRoutes = () => (<App>
    <Switch>
        <Route exact path="/" component={Landing}/>
        <Route exact path="/dash-board" component={DashBoard}/>
        <Route exact path="/api"/>
        <Route component={Page404}/>
    </Switch>
</App>);

export default AppRoutes;
