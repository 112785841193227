import {
    DELETE_TYPE_EVENT,
    LOADING_TYPE_EVENTS,
    SELECTED_COLOR,
    SELECTED_TYPE_EVENT,
    TYPE_EVENTS,
    UPDATE_TYPE_EVENT
} from '../../actions/types';

const initialState = {
        loading: false,
        type_event: 1,
        type_events: [],
        color: {
            hex:'#333'
        }
    }
;

export default function TypeEventsReducer(state = initialState, action) {

    switch (action.type) {
        case TYPE_EVENTS:
            return {
                ...state,
                type_events: action.payload,
                loading: false
            };
        case DELETE_TYPE_EVENT:
            return {
                ...state,
                type_events: action.payload,
                loading: false
            };
        case UPDATE_TYPE_EVENT:
            return {
                ...state,
                type_events: action.payload,
                loading: false
            };
        case SELECTED_TYPE_EVENT:
            return {
                ...state,
                type_event: action.payload
            };
        case SELECTED_COLOR:
            return {
                ...state,
                color: action.payload
            };
        case LOADING_TYPE_EVENTS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
