import {HOME, LOADING, LOADING_MENU, LOGIN, LOGOUT, MENUS, SELECTED_MENU, SET_MARCA} from './types';
import {AppEnv} from '../../components/env';
import axios from 'axios';
import Functions from '../../components/Global/Functions';

const apiUrl = AppEnv.api;

// <=<●>=><=<●>=><=<●>=> Global <=<●>=><=<●>=><=<●>=>

export function setLoading(data) {
    return {
        type: LOADING,
        payload: data
    };
}

// <=<●>=><=<●>=><=<●>=> Login <=<●>=><=<●>=><=<●>=>

export function login(fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'auth/login', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json'
                }
            }
        ).then((response) => {
            dispatch(fetchLoginSuccess(response.data));
        }).catch(error => {
            if (error.response.status === 401) {
                dispatch(fetchLoginUnauthorized());
            } else {
                dispatch(fetchLoginFailed());
            }
        });
    };
}

export function fetchLoginSuccess(response) {
    return {
        type: LOGIN,
        payload: response
    };
}

export function fetchLoginFailed() {
    return {
        type: LOGIN,
        payload: {
            token: '',
            userId: '',
            userRole: '',
            userName: ''
        }
    };
}

export function fetchLoginUnauthorized() {
    Functions.alertWarning('Datos incorrectos', 'Verifique que su usuario y contraseña estén correctamente escritos');
    return {
        type: LOGIN,
        payload: {
            token: '',
            userId: '',
            userRole: '',
            userName: ''
        }
    };
}

// <=<●>=><=<●>=><=<●>=> Menus <=<●>=><=<●>=><=<●>=>
export function logOut() {
    sessionStorage.setItem('token', '');
    sessionStorage.setItem('userId', '');
    sessionStorage.setItem('userRole', '');
    sessionStorage.setItem('userName', '');
    return {
        type: LOGOUT,
        payload: {
            token: '',
            userId: '',
            userRole: '',
            userName: ''
        }
    };
}

// <=<●>=><=<●>=><=<●>=> Menus <=<●>=><=<●>=><=<●>=>

export function setLoadingMenu(data) {
    return {
        type: LOADING_MENU,
        payload: data
    };
}

export function fetchMenus(token, role) {
    return (dispatch) => {
        dispatch(setLoadingMenu(true));
        axios.get(apiUrl + 'countries/funcionalidades/' + role, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchMenusSuccess(response.data));
        }).catch(error => {
            dispatch(fetchMenusFailed());
        });
    };
}

export function fetchMenusSuccess(response) {
    return {
        type: MENUS,
        payload: response
    };
}

export function fetchMenusFailed() {
    return {
        type: MENUS,
        payload: []
    };
}

export function selectMenu(name) {
    return {
        type: SELECTED_MENU,
        payload: name
    };
}

export function goHome() {
    return {
        type: HOME,
        payload: ''
    };
}

export function setMarca(data) {
    sessionStorage.setItem('mid', data.id);
    sessionStorage.setItem('brand', data.name);
    sessionStorage.setItem('url', data.url);
    sessionStorage.setItem('bd', data.bd);
    return {
        type: SET_MARCA,
        payload: data
    };
}


