import {HOME, LOADING_MENU, MENUS, SELECTED_MENU} from '../actions/types';

const initialState = {
    loading: false,
    menus: [],
    selectedMenu: 0
};

export default function menuReducer(state = initialState, action) {

    switch (action.type) {
        case MENUS:
            return {
                ...state,
                menus: action.payload,
                loading: false
            };
        case SELECTED_MENU:
            return {
                ...state,
                selectedMenu: action.payload,
                loading: false
            };
        case HOME:
            return {
                ...state,
                selectedMenu: action.payload,
                loading: false
            };
        case LOADING_MENU:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: action.payload
            };
    }
}