import React, {useEffect, useState} from 'react';
import {
    Button,
    Confirm,
    Divider,
    Dropdown,
    Grid,
    Header,
    Icon,
    Input,
    Item,
    Modal
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {createRole, deleteRole, fetchRoles, updateRole} from '../../redux/actions/RolesActions/RolesActions';
import {fetchPermissions} from '../../redux/actions/RolesActions/PermissionsActions';
import Functions from '../Global/Functions';

export default function CreateRoles() {
    const [nombre, setNombre] = useState('');
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [modal, setModal] = useState(false);
    const [selected, setSelected] = useState('');
    const [selectedPermissions, setPermissions] = useState([]);
    const loading = useSelector(state => state.roles.loading);
    const roles = useSelector(state => state.roles.roles);
    const permissions = useSelector(state => state.permissions.permissions);
    const token = useSelector(state => state.root.token);
    const dispatch = useDispatch();
    const rolesRendered = roles.map(it =>
        (<Item key={it.id}>
            <Item.Content verticalAlign='middle'>
                <Item.Header>{it.nombre}</Item.Header>
                <Item.Extra floated='right'>
                    {it.permissions.map(itp => (
                        <Item key={itp.id + 'perm'}>
                            <Item.Content verticalAlign='middle'>
                                <Item.Header>{itp.permission.nombre}</Item.Header>
                            </Item.Content>
                        </Item>
                    ))}
                    <Button size='mini' content='Borrar' icon='delete' labelPosition='left' onClick={() => {
                        setSelected(it);
                        setConfirmDelete(true);
                    }}/>
                    <Button
                        size='mini'
                        content='Editar'
                        icon='edit'
                        labelPosition='right'
                        onClick={() => {
                            setSelected(it);
                            setNombre(it.nombre);
                            setPermissions(it.permissions.map(it => (
                                it.permission_id
                            )));
                            setModal(true);
                        }}/>
                </Item.Extra>
            </Item.Content>
        </Item>)
    );
    const renderPermissions = permissions.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const save = () => {
        if (nombre && selectedPermissions) {
            let fd = JSON.stringify({
                permisssions: selectedPermissions
            });
            dispatch(createRole(token, nombre, fd));
            setNombre('');

        } else {
            Functions.alertWarning();
        }
    };
    const edit = () => {
        if (nombre && selectedPermissions) {
            let fd = JSON.stringify({
                permisssions: selectedPermissions
            });
            dispatch(updateRole(token, nombre, fd, selected.id));
        } else {
            Functions.alertWarning();
        }
    };


    useEffect(() => {
        async function getRoles() {
            await dispatch(fetchRoles());
        }

        getRoles();
    }, []);
    useEffect(() => {
        async function getPermissions() {
            await dispatch(fetchPermissions());
        }

        getPermissions();
    }, []);

    return (
        <Grid className='no-margin' style={{width: '100%'}}>
            {/*<=<●>=><=<●>=><=<●>=> Confirmar Borrado <=<●>=><=<●>=><=<●>=>*/}
            <Confirm
                open={confirmDelete}
                content='¿Está seguro que quiere eliminar este rol?'
                cancelButton={<Button color='red' icon='close' content='Cancelar' inverted/>}
                confirmButton={<Button color='green' icon='checkmark' content='Si, eliminar'/>}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={() => [dispatch(deleteRole(token, selected.id)), setConfirmDelete(false)]}
            />

            <Modal size='tiny' open={modal}>
                <Modal.Header>Editando: {selected.nombre}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Ingrese un nombre</label>
                                    <Input
                                        onChange={(e) => setNombre(e.target.value)}
                                        placeholder='Nombre'
                                        size={'large'}
                                        value={nombre}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione los permisos</label>
                                    <Dropdown
                                        value={selectedPermissions}
                                        onChange={(e, data) => setPermissions(data.value)}
                                        placeholder='Seleccione los permisos' fluid multiple selection
                                        options={renderPermissions}/>
                                    <Divider className='divider-5' hidden/>
                                    <Button
                                        fluid
                                        color='teal'
                                        loading={loading}
                                        onClick={edit}
                                        content='Guardar'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModal(false);
                            setSelected('');
                            setNombre('');
                            setPermissions([]);
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Grid.Row
                stretched
                column={2}
                verticalAlign='top'
                className='no-margin'>
                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Crear roles</Header>
                    <Grid centered className='no-margin'>
                        <Grid.Row
                            centered
                            className='no-margin scroll'
                            stretched
                            verticalAlign='middle'>
                            <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                                textAlign='center'>
                                <label className='truncate subtitle'>Ingrese un nombre</label>
                                <Input
                                    onChange={(e) => setNombre(e.target.value)}
                                    placeholder='Nombre'
                                    size={'large'}
                                    value={nombre}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione los permisos</label>
                                <Dropdown
                                    value={selectedPermissions}
                                    onChange={(e, data) => setPermissions(data.value)}
                                    placeholder='Seleccione los permisos' fluid multiple selection
                                    options={renderPermissions}/>
                                <Divider className='divider-5' hidden/>
                                <Button
                                    fluid
                                    color='teal'
                                    loading={loading}
                                    onClick={save}
                                    content='Guardar'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>

                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Consultar tipos de niveles</Header>
                    <Grid.Row
                        stretched
                        className='no-margin scroll'
                        style={{backgroundColor: '#fff', padding: 10, borderRadius: 10}}>
                        <Item.Group divided relaxed style={{width: '100%'}}>
                            {roles.length !== 0
                                ? loading
                                    ? Functions.loadingMessage()
                                    : rolesRendered
                                : loading
                                    ? Functions.loadingMessage()
                                    : Functions.nothingMessage()}
                        </Item.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
}
