import {LOADING_TIPOS_MATERIAS, SELECTED_TIPO_MATERIA, TIPOS_MATERIAS} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_TIPOS_MATERIAS,
        payload: data
    };
}

export function createTipoMateria(token, country, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'tipo/materia/create', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTiposMaterias(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTiposMaterias(token, country));
        });
    };
}

export function fetchTiposMaterias(token, country) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'tipo/materia/get/' + country, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchTiposMateriasSuccess(response.data));
        }).catch(error => {
            dispatch(fetchTiposMateriasFailed());
        });
    };
}

export function updateTipoMateria(token, country, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'tipo/materia/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTiposMaterias(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTiposMaterias(token, country));
        });
    };
}

export function deleteTipoMateria(token, country, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'tipo/materia/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTiposMaterias(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTiposMaterias(token, country));
        });
    };
}

export function fetchTiposMateriasSuccess(response) {
    return {
        type: TIPOS_MATERIAS,
        payload: response
    };
}

export function fetchTiposMateriasFailed() {
    Functions.alertFail();
    return {
        type: TIPOS_MATERIAS,
        payload: []
    };
}

export function setTipoMateria(TiposMaterias) {
    return {
        type: SELECTED_TIPO_MATERIA,
        payload: TiposMaterias
    };
}
