import {PARTNERS, DELETE_PARTNERS, LOADING_PARTNERS, UPDATE_PARTNERS} from '../../actions/types';

const initialState = {
    loading: false,
    partners: []
};

export default function PartnersReducer(state = initialState, action) {

    switch (action.type) {
        case PARTNERS:
            return {
                ...state,
                partners: action.payload,
                loading: false
            };
        case DELETE_PARTNERS:
            return {
                ...state,
                partners: action.payload,
                loading: false
            };
        case UPDATE_PARTNERS:
            return {
                ...state,
                partners: action.payload,
                loading: false
            };
        case LOADING_PARTNERS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: action.payload
            };
    }
}