import React from 'react';
import reportWebVitals from './reportWebVitals';
import {render} from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import "./sass/app.scss"

import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';

import reducer from './redux/reducers/index';
import AppRoutes from './routes';

const store = createStore(reducer, applyMiddleware(thunk));

render(
    <Provider store={store}>
        <Router>
            <AppRoutes/>
        </Router>
    </Provider>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
