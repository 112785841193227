import React, {useEffect, useState} from 'react';
import {
    Button,
    Confirm,
    Divider,
    Flag,
    Grid,
    Header,
    Icon,
    Input,
    Item,
    Modal,
        Select
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {
    createMateria,
    deleteMateria,
    fetchMaterias,
    updateMateria
} from '../../redux/actions/CountriesActions/MateriasActions';
import Functions from '../Global/Functions';
import TipoMateria from '../Configuracion/TipoMateria';
import {fetchNiveles, setNivel} from '../../redux/actions/CountriesActions/NivelesActions';
import {fetchGrados, setGrado} from '../../redux/actions/CountriesActions/GradosActions';
import {fetchTipoNiveles, setTipoNivel} from '../../redux/actions/CountriesActions/TipoNivelesActions';
import {fetchCiclos, setCiclo} from '../../redux/actions/CountriesActions/CiclosActions';
import {fetchTiposMaterias, setTipoMateria} from '../../redux/actions/CountriesActions/TipoMateriaActions';

function Materias() {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [horas, setHoras] = useState('');
    const [modal, setModal] = useState(false);
    const [nombre, setNombre] = useState('');
    const [selected, setSelected] = useState('');
    const [tipo, setTipo] = useState('');
    const [modalTipoMateria, setModalTipoMateria] = useState(false);
    const ciclo = useSelector(state => state.ciclos.ciclo);
    const ciclos = useSelector(state => state.ciclos.ciclos);
    const country = useSelector(state => state.countries.country);
    const dispatch = useDispatch();
    const grado = useSelector(state => state.grados.grado);
    const grados = useSelector(state => state.grados.grados);
    const loading = useSelector(state => state.materias.loading);
    const materias = useSelector(state => state.materias.materias);
    const nivel = useSelector(state => state.niveles.nivel);
    const niveles = useSelector(state => state.niveles.niveles);
    const tipoMateria = useSelector(state => state.tipos_materias.tipo_materia);
    const tiposMaterias = useSelector(state => state.tipos_materias.tipos_materias);
    const tipoNivel = useSelector(state => state.tipo_niveles.tipo_nivel);
    const tipoNiveles = useSelector(state => state.tipo_niveles.tipo_niveles);
    const token = useSelector(state => state.root.token);
    const renderNiveles = niveles.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const renderGrados = grados.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const renderCiclos = ciclos.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const renderTipoNiveles = tipoNiveles.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const renderTipoMaterias = tiposMaterias.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const save = () => {
        if (nombre && horas) {
            let fd = JSON.stringify({
                id_pais: country.id,
                id_nivel: nivel,
                id_grado: grado,
                id_ciclo: ciclo,
                tipo_nivel: tipoNivel,
                tipo: tipoMateria,
                horas: horas.toUpperCase(),
                nombre: nombre.toUpperCase()
            });
            dispatch(createMateria(token, country.id, nivel, ciclo, tipoNivel, grado, fd));
            setNombre('');
            setHoras('');
            setTipo('');
        } else {
            Functions.alertWarning();
        }
    };

    const edit = () => {
        if (nombre && horas) {
            let fd = JSON.stringify({
                id_pais: country.id,
                id_nivel: nivel,
                id_grado: grado,
                id_ciclo: ciclo,
                tipo_nivel: tipoNivel,
                tipo: tipoMateria,
                horas: horas.toUpperCase(),
                nombre: nombre.toUpperCase()
            });
            dispatch(updateMateria(token, country.id, nivel, ciclo, tipoNivel, grado, fd, selected.id));
            setNombre('');
            setHoras('');
            setTipo('');
            setModal(false);
        } else {
            Functions.alertWarning();
        }
    };

    const materiasRendered = materias.map(it =>
        (<Item key={it.id}>
            <Flag name={it.bandera}/>
            <Item.Content verticalAlign='middle'>
                <Item.Header>{it.nombre}</Item.Header>
                <Item.Meta>
                    <span className='cinema'>Tipo: {it.tipo_materia.nombre}</span>
                    <br/>
                    <span className='cinema'>Horas a la semana: {it.horas}</span>
                </Item.Meta>
                <Item.Extra floated='right'>
                    <Button size='mini' content='Borrar' icon='delete' labelPosition='left' onClick={() => {
                        setSelected(it.id);
                        setConfirmDelete(true);
                    }}/>
                    <Button
                        size='mini'
                        content='Editar'
                        icon='edit'
                        labelPosition='right'
                        onClick={() => {
                            setSelected(it);
                            dispatch(setNivel(Number(it.id_nivel)));
                            dispatch(setGrado(Number(it.id_grado)));
                            setNombre(it.nombre);
                            setTipo(it.tipo);
                            setHoras(it.horas);
                            setModal(true);
                        }}/>
                </Item.Extra>
            </Item.Content>
        </Item>)
    );

    useEffect(() => {
        async function getNiveles() {
            await dispatch(fetchNiveles(token, country.id));
        }

        getNiveles();
    }, [country]);

    useEffect(() => {
        async function getTipoNiveles() {
            await dispatch(fetchTipoNiveles(token, country.id, nivel));
        }

        getTipoNiveles();
    }, [nivel]);

    useEffect(() => {
        async function getCiclos() {
            await dispatch(fetchCiclos(token, country.id, nivel));
        }

        getCiclos();
    }, [nivel]);

    useEffect(() => {
        async function getGrados() {
            await dispatch(fetchGrados(token, country.id, nivel, tipoNivel, ciclo));
        }

        getGrados();
    }, [nivel, ciclo, tipoNivel]);

    useEffect(() => {
        async function materias() {
            await dispatch(fetchMaterias(token, country.id, nivel, ciclo, tipoNivel, grado));
        }

        materias();
    }, [grado]);

    useEffect(() => {
        async function asd() {
            await dispatch(fetchTiposMaterias(token, country.id));
        }

        asd();
    }, [country]);

    return (
        <Grid className='no-margin' style={{width: '100%'}}>
            {/*<=<●>=><=<●>=><=<●>=> Confirmar Borrado <=<●>=><=<●>=><=<●>=>*/}
            <Confirm
                open={confirmDelete}
                content='¿Está seguro que quiere eliminar esta materia?'
                cancelButton={<Button color='red' icon='close' content='Cancelar' inverted/>}
                confirmButton={<Button color='green' icon='checkmark' content='Si, eliminar'/>}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={() => [dispatch(deleteMateria(token, country.id, nivel, ciclo, tipoNivel, grado, selected)), setConfirmDelete(false)]}
            />

            <Modal size='tiny' open={modal}>
                <Modal.Header>Editando: {selected.nombre}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    <label className='truncate subtitle'>Seleccione un nivel</label>
                                    <Select
                                        value={nivel}
                                        onChange={(e, data) => dispatch(setNivel(data.value))}
                                        placeholder='Selecciona el nivel'
                                        options={renderNiveles}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione un tipo de nivel</label>
                                    <Select
                                        value={tipoNivel}
                                        onChange={(e, data) => dispatch(setTipoNivel(data.value))}
                                        placeholder='Selecciona el tipo de nivel'
                                        options={renderTipoNiveles}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione un ciclo</label>
                                    <Select
                                        value={ciclo}
                                        onChange={(e, data) => dispatch(setCiclo(data.value))}
                                        placeholder='Selecciona el ciclo'
                                        options={renderCiclos}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione un grado</label>
                                    <Select
                                        value={grado}
                                        onChange={(e, data) => dispatch(setGrado(data.value))}
                                        placeholder='Selecciona el grado'
                                        options={renderGrados}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Ingrese un nombre</label>
                                    <Input
                                        fluid
                                        onChange={(e) => setNombre(e.target.value)}
                                        placeholder='Nombre de la materia'
                                        size={'large'}
                                        style={{textTransform: 'uppercase'}}
                                        value={nombre}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Ingrese las horas a la semana</label>
                                    <Input
                                        fluid
                                        onChange={(e) => setHoras(e.target.value)}
                                        placeholder='Horas a la semana'
                                        size={'large'}
                                        style={{textTransform: 'uppercase'}}
                                        value={horas}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione un tipo de materia</label>
                                    <Button fluid as='div' labelPosition='left'>
                                        <Select
                                            fluid
                                            attached='right'
                                            value={tipoMateria}
                                            onChange={(e, data) => dispatch(setTipoMateria(data.value))}
                                            placeholder='Selecciona el tipo'
                                            options={renderTipoMaterias}/>
                                        <Button onClick={() => setModalTipoMateria(true)} icon>
                                            <Icon name='plus'/>
                                        </Button>
                                    </Button>
                                    <Divider className='divider-5' hidden/>
                                    <Button
                                        fluid
                                        color='teal'
                                        loading={loading}
                                        onClick={edit}
                                        content='Guardar'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModal(false);
                            setSelected('');
                            setNombre('');
                            setTipo('');
                            setHoras('');
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal size='large' open={modalTipoMateria}>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <TipoMateria/>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModalTipoMateria(false);
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Grid.Row
                stretched
                column={2}
                verticalAlign='top'
                className='no-margin'>
                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Crear materia</Header>
                    <Grid centered className='no-margin'>
                        <Grid.Row
                            centered
                            className='no-margin scroll1'
                            stretched
                            verticalAlign='middle'>
                            <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                                textAlign='center'>
                                <label className='truncate subtitle'>Seleccione un nivel</label>
                                <Select
                                    value={nivel}
                                    onChange={(e, data) => dispatch(setNivel(data.value))}
                                    placeholder='Selecciona el nivel'
                                    options={renderNiveles}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un tipo de nivel</label>
                                <Select
                                    value={tipoNivel}
                                    onChange={(e, data) => dispatch(setTipoNivel(data.value))}
                                    placeholder='Selecciona el tipo de nivel'
                                    options={renderTipoNiveles}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un ciclo</label>
                                <Select
                                    value={ciclo}
                                    onChange={(e, data) => dispatch(setCiclo(data.value))}
                                    placeholder='Selecciona el ciclo'
                                    options={renderCiclos}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un grado</label>
                                <Select
                                    value={grado}
                                    onChange={(e, data) => dispatch(setGrado(data.value))}
                                    placeholder='Selecciona el grado'
                                    options={renderGrados}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Ingrese un nombre</label>
                                <Input
                                    fluid
                                    onChange={(e) => setNombre(e.target.value)}
                                    placeholder='Nombre de la materia'
                                    size={'large'}
                                    style={{textTransform: 'uppercase'}}
                                    value={nombre}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Ingrese las horas a la semana</label>
                                <Input
                                    fluid
                                    onChange={(e) => setHoras(e.target.value)}
                                    placeholder='Horas a la semana'
                                    size={'large'}
                                    style={{textTransform: 'uppercase'}}
                                    value={horas}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un tipo de materia</label>
                                <Button fluid as='div' labelPosition='left'>
                                    <Select
                                        fluid
                                        attached='right'
                                        value={tipoMateria}
                                        onChange={(e, data) => dispatch(setTipoMateria(data.value))}
                                        placeholder='Selecciona el tipo'
                                        options={renderTipoMaterias}/>
                                    <Button onClick={() => setModalTipoMateria(true)} icon>
                                        <Icon name='plus'/>
                                    </Button>
                                </Button>
                                <Divider className='divider-5' hidden/>
                                <Button
                                    fluid
                                    color='teal'
                                    loading={loading}
                                    onClick={save}
                                    content='Guardar'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>

                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Consultar materias</Header>
                    <Grid.Row
                        stretched
                        className='no-margin scroll1'
                        style={{backgroundColor: '#fff', padding: 10, borderRadius: 10}}>
                        <Item.Group divided relaxed style={{width: '100%'}}>
                            {materias.length !== 0
                                ? loading
                                    ? Functions.loadingMessage()
                                    : materiasRendered
                                : loading
                                    ? Functions.loadingMessage()
                                    : Functions.nothingMessage()}
                        </Item.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
}

export default Materias;
