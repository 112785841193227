import React, {useEffect, useState} from 'react';
import {
    Button,
    Confirm,
    Divider,
    Dropdown,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    Item,
    Modal,
    Select,
    TextArea
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from "react-redux";
import Functions from "../Global/Functions";
import {
    createAvisos,
    deleteAvisos,
    fetchAvisos,
    fetchDestinatarios,
    updateAvisos
} from "../../redux/actions/AvisosActions/AvisosActions";

export default function CrearAviso() {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selected, setSelected] = useState('');
    const [modal, setModal] = useState(false);
    const loading = useSelector(state => state.avisos.loading);
    const token = useSelector(state => state.root.token);

    const destinatarios = useSelector(state => state.avisos.destinatarios);
    const avisos = useSelector(state => state.avisos.avisos);
    const roles = useSelector(state => state.avisos.roles);
    const [web, setWeb] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [tipoDestino, setTipoDestino] = useState('');
    const [destino, setDestino] = useState('');
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [status, setStatus] = useState(0);
    const tiposDestino = [{nombre: 'Escuela', tipo: 'Escuela'},
        {nombre: 'Instituciones', tipo: 'Instituciones'},
        {nombre: 'País', tipo: 'Pais'},
        {nombre: 'Todas las escuelas', tipo: 'Todas'},
    ];

    const dispatch = useDispatch();

    useEffect(() => {
        if (tipoDestino !== '') {
            dispatch(fetchDestinatarios(token, tipoDestino))
        }
    }, [tipoDestino])

    useEffect(() => {
        dispatch(fetchAvisos(token))
    }, [])

    const renderDestinatarios = destinatarios.map(it => {

        if (tipoDestino === 'Escuela') {
            return {key: it.id, value: it.id, text: it.nombre_escuela + ' (' + it.nivel + ') '}
        } else if (tipoDestino === 'Instituciones') {
            return {key: it.id, value: it.conjunto_tres, text: it.conjunto_tres}
        } else if (tipoDestino === 'Pais') {
            return {key: it.id, value: it.id, text: it.nombre}
        } else if (tipoDestino === 'Todas') {
            return {key: it.nombre, value: it.nombre, text: it.nombre}
        }

    });

    const renderTipoDestinatarios = tiposDestino.map(it => (
        {key: it.tipo, value: it.tipo, text: it.nombre}
    ));

    const renderRoles = roles.map((it, i) => (
        {key: i, value: it.puesto, text: it.role_name + ' (' + it.puesto_name + ')'}
    ));


    const save = () => {
        if (titulo !== '' && descripcion !== '' && selectedRoles.length !== 0 && destino !== '' && status !== 0 && (web || mobile)) {
            let fd = JSON.stringify({
                titulo: titulo,
                descripcion: descripcion,
                status: status,
                web: web,
                mobile: mobile,
                tipo_destino: tipoDestino,
                destino: destino,
                selectedRoles: selectedRoles,
            });
            dispatch(createAvisos(token, fd));
            setTitulo('');
        } else {
            Functions.alertWarning();
        }
    };

    const edit = () => {
        if (selected.titulo !== '' && selected.descripcion !== '' && selected.destino !== '' && selected.status !== 0 && (selected.web || selected.mobile)) {
            let fd = JSON.stringify({
                titulo: selected.titulo,
                descripcion: selected.descripcion,
                status: selected.status,
                web: selected.web,
                mobile: selected.mobile,
                tipo_destino: selected.tipoDestino,
                destino: selected.destino,
            });
            dispatch(updateAvisos(token, fd, selected.id));
            setModal(false);
        } else {
            Functions.alertWarning();
        }
    };

    const avisosRendered = avisos.map(it =>
        (<Item key={it.id}>
            {/*<Flag name={it.bandera}/>*/}
            <Item.Content verticalAlign='middle'>
                <Item.Header>{it.titulo}</Item.Header>
                <Item.Meta>Descripción: {it.descripcion}</Item.Meta>
                <Item.Meta>Tipo: {it.tipo_destino}</Item.Meta>
                <Item.Meta>Destino: {it.destino}</Item.Meta>
                <Item.Meta>Puestos: </Item.Meta>
                <Item.Extra floated='right'>
                    <Button size='mini' content='Borrar' icon='delete' labelPosition='left' onClick={() => {
                        setSelected(it.id);
                        setConfirmDelete(true);
                    }}/>
                    <Button
                        size='mini'
                        content='Editar'
                        icon='edit'
                        labelPosition='right'
                        onClick={() => {
                            setSelected(it);
                            // dispatch(setNivel(Number(it.id_nivel)));
                            // setNombre(it.titulo);
                            setModal(true);
                        }}/>
                </Item.Extra>
            </Item.Content>
        </Item>)
    );

    const setSelectedProp = (prop, value) => {
        setSelected({...selected, [prop]: value})
    }

    return (
        <Grid className='no-margin' style={{width: '100%'}}>
            {/*<=<●>=><=<●>=><=<●>=> Confirmar Borrado <=<●>=><=<●>=><=<●>=>*/}
            <Confirm
                open={confirmDelete}
                content='¿Está seguro que quiere eliminar este grado?'
                cancelButton={<Button color='red' icon='close' content='Cancelar' inverted/>}
                confirmButton={<Button color='green' icon='checkmark' content='Si, eliminar'/>}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={() => [dispatch(deleteAvisos(token, selected)), setConfirmDelete(false)]}
            />

            <Modal size='tiny' open={modal}>
                <Modal.Header>Editando: {selected.titulo}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>

                                    <label className='truncate subtitle'>Ingrese un título</label>
                                    <Input
                                        fluid
                                        onChange={(e) => setSelectedProp('titulo', e.target.value)}
                                        placeholder='Título'
                                        size={'large'}
                                        value={selected.titulo}/>
                                    <Divider className='divider-5' hidden/>

                                    <label className='truncate subtitle'>Ingrese una descripción</label>
                                    <Form>
                                        <TextArea
                                            rows={4}
                                            onChange={(e) => setSelectedProp('descripcion', e.target.value)}
                                            placeholder='Descripción'
                                            size={'large'}
                                            value={selected.descripcion}/>
                                    </Form>
                                    <Divider className='divider-5' hidden/>

                                    <label className='truncate subtitle'>Seleccione un status</label>
                                    <Button.Group>
                                        <Button
                                            onClick={() => setSelectedProp('status', '1')}
                                            basic={selected.status !== '1'}
                                            color='red'>
                                            Pospuesto
                                        </Button>
                                        <Button.Or text='O'/>
                                        <Button
                                            onClick={() => setSelectedProp('status', '2')}
                                            basic={selected.status !== '2'}
                                            color='orange'>
                                            Proceso
                                        </Button>
                                        <Button.Or text='O'/>
                                        <Button
                                            onClick={() => setSelectedProp('status', '3')}
                                            basic={selected.status !== '3'}
                                            color='green'>
                                            Resuelto
                                        </Button>
                                    </Button.Group>
                                    <Divider className='divider-5' hidden/>

                                    <label className='truncate subtitle'>Seleccione en donde será visualizado</label>
                                    <Button.Group>
                                        <Button
                                            onClick={() => setSelectedProp('web', selected.web === '1' ? '0' : '1')}
                                            basic={selected.web !== '1'}
                                            color='teal'
                                            icon='computer'
                                            content='WEB'/>
                                        <Button.Or text='Y'/>
                                        <Button
                                            onClick={() => setSelectedProp('mobile', selected.mobile === '1' ? '0' : '1')}
                                            basic={selected.mobile !== '1'}
                                            color='teal'
                                            icon='mobile'
                                            content='MOBILE'/>
                                    </Button.Group>
                                    <Divider className='divider-5' hidden/>

                                    <Button
                                        fluid
                                        color='teal'
                                        loading={loading}
                                        onClick={edit}
                                        content='Guardar'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModal(false);
                            setSelected('');
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Grid.Row
                stretched
                column={2}
                verticalAlign='top'
                className='no-margin'>
                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Crear aviso</Header>
                    <Grid centered className='no-margin'>
                        <Grid.Row
                            centered
                            className='no-margin scroll1'
                            stretched
                            verticalAlign='middle'>
                            <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                                textAlign='center'>

                                <label className='truncate subtitle'>Seleccione el tipo de destino</label>
                                <Select
                                    value={tipoDestino}
                                    onChange={(e, data) => setTipoDestino(data.value)}
                                    placeholder='Seleccione el tipo de destino'
                                    options={renderTipoDestinatarios}/>
                                <Divider className='divider-5' hidden/>

                                <label className='truncate subtitle'>Seleccione el destino</label>
                                <Select
                                    value={destino}
                                    onChange={(e, data) => setDestino(data.value)}
                                    placeholder='Seleccione el destino'
                                    options={renderDestinatarios}/>
                                <Divider className='divider-5' hidden/>

                                <label className='truncate subtitle'>Seleccione los roles</label>
                                <Dropdown
                                    value={selectedRoles}
                                    onChange={(e, data) => setSelectedRoles(data.value)}
                                    placeholder='Seleccione los permisos' fluid multiple selection
                                    options={renderRoles}/>
                                <Divider className='divider-5' hidden/>

                                <label className='truncate subtitle'>Ingrese un título</label>
                                <Input
                                    fluid
                                    onChange={(e) => setTitulo(e.target.value)}
                                    placeholder='Título'
                                    size={'large'}
                                    value={titulo}/>
                                <Divider className='divider-5' hidden/>

                                <label className='truncate subtitle'>Ingrese una descripción</label>
                                <Form>
                                    <TextArea
                                        rows={4}
                                        onChange={(e) => setDescripcion(e.target.value)}
                                        placeholder='Descripción'
                                        size={'large'}
                                        value={descripcion}/>
                                </Form>
                                <Divider className='divider-5' hidden/>

                                <label className='truncate subtitle'>Seleccione un status</label>
                                <Button.Group>
                                    <Button
                                        onClick={() => setStatus(1)}
                                        basic={status !== 1}
                                        color='red'>
                                        Pospuesto
                                    </Button>
                                    <Button.Or text='O'/>
                                    <Button
                                        onClick={() => setStatus(2)}
                                        basic={status !== 2}
                                        color='orange'>
                                        Proceso
                                    </Button>
                                    <Button.Or text='O'/>
                                    <Button
                                        onClick={() => setStatus(3)}
                                        basic={status !== 3}
                                        color='green'>
                                        Resuelto
                                    </Button>
                                </Button.Group>
                                <Divider className='divider-5' hidden/>

                                <label className='truncate subtitle'>Seleccione en donde será visualizado</label>
                                <Button.Group>
                                    <Button
                                        onClick={() => setWeb(!web)}
                                        basic={!web}
                                        color='teal'
                                        icon='computer'
                                        content='WEB'/>
                                    <Button.Or text='Y'/>
                                    <Button
                                        onClick={() => setMobile(!mobile)}
                                        basic={!mobile}
                                        color='teal'
                                        icon='mobile'
                                        content='MOBILE'/>
                                </Button.Group>
                                <Divider className='divider-5' hidden/>

                                <Button
                                    fluid
                                    color='teal'
                                    loading={loading}
                                    onClick={save}
                                    content='Guardar'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>

                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Consultar avisos</Header>
                    <Grid.Row
                        stretched
                        className='no-margin scroll1'
                        style={{backgroundColor: '#fff', padding: 10, borderRadius: 10}}>
                        <Item.Group divided relaxed style={{width: '100%'}}>
                            {avisos.length !== 0
                                ? loading
                                    ? Functions.loadingMessage()
                                    : avisosRendered
                                : loading
                                    ? Functions.loadingMessage()
                                    : Functions.nothingMessage()}
                        </Item.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
}