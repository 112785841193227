import {AVISOS_DESTINATARIOS, GET_AVISOS, LOADING_AVISOS} from '../../actions/types';

const initialState = {
        loading: false,
        destinatarios: [],
        avisos: [],
        roles: [
            {
                id: 1,
                role: 'Admin',
                role_name: 'Administrador',
                puesto: 'Director',
                puesto_name: 'Director'
            },
            {
                id: 2,
                role: 'Admin',
                role_name: 'Administrador',
                puesto: 'SubDirector',
                puesto_name: 'Subdirector'
            },
            {
                id: 3,
                role: 'Admin',
                role_name: 'Administrador',
                puesto: 'Coordinador Academico',
                puesto_name: 'Coordinador Académico'
            },
            {
                id: 4,
                role: 'Admin',
                role_name: 'Administrador',
                puesto: 'Cordisi',
                puesto_name: 'Coordinador disciplina'
            },
            {
                id: 5,
                role: 'Admin',
                role_name: 'Administrador',
                puesto: 'Secretaria',
                puesto_name: 'Coordinador administrativo'
            },
            {
                id: 6,
                role: 'Padre',
                role_name: 'Padre',
                puesto: 'Padre',
                puesto_name: 'Padre'
            },
            {
                id: 7,
                role: 'Maestro',
                role_name: 'Maestro',
                puesto: 'Maestro',
                puesto_name: 'Maestro'
            },
            {
                id: 8,
                role: 'Prefecto',
                role_name: 'Prefecto',
                puesto: 'Prefecto',
                puesto_name: 'Prefecto'
            },
            {
                id: 9,
                role: 'Alumno',
                role_name: 'Alumno',
                puesto: 'Alumno',
                puesto_name: 'Alumno'
            },
        ],
    }
;

export default function AvisosReducer(state = initialState, action) {

    switch (action.type) {
        case AVISOS_DESTINATARIOS:
            return {
                ...state,
                destinatarios: action.payload,
                loading: false
            };
        case GET_AVISOS:
            return {
                ...state,
                avisos: action.payload,
                loading: false
            };
        case LOADING_AVISOS:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}