import React, {useEffect, useState} from 'react';
import {
    Button,
    Confirm,
    Divider,
    Grid,
    Header,
    Icon,
    Input,
    Item,
    Label,
    Modal,
    Select
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {
    createTipoNivel,
    deleteTipoNivel,
    fetchTipoNiveles,
    updateTipoNivel
} from '../../redux/actions/CountriesActions/TipoNivelesActions';
import {fetchNiveles, setNivel} from '../../redux/actions/CountriesActions/NivelesActions';
import Functions from '../Global/Functions';

function TipoNiveles() {
    const [nombre, setNombre] = useState('');
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [modal, setModal] = useState(false);
    const [selected, setSelected] = useState('');
    const tipo_niveles = useSelector(state => state.tipo_niveles.tipo_niveles);
    const loading = useSelector(state => state.tipo_niveles.loading);
    const country = useSelector(state => state.countries.country);
    const niveles = useSelector(state => state.niveles.niveles);
    const nivel = useSelector(state => state.niveles.nivel);
    const token = useSelector(state => state.root.token);
    const dispatch = useDispatch();
    const renderNiveles = niveles.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const save = () => {
        if (nivel && nombre) {
            let fd = JSON.stringify({
                id_pais: country.id,
                id_nivel: nivel,
                nombre: nombre.toUpperCase()
            });
            dispatch(createTipoNivel(token, country.id, nivel, fd));
            setNombre('');

        } else {
            Functions.alertWarning();
        }
    };
    const edit = () => {
        if (nivel && nombre) {
            let fd = JSON.stringify({
                id_pais: country.id,
                id_nivel: nivel,
                nombre: nombre.toUpperCase()
            });
            dispatch(updateTipoNivel(token, country.id, nivel, fd, selected.id));
            setSelected('');
            setNombre('');
            setModal(false);
        } else {
            Functions.alertWarning();
        }
    };
    const tipoNivelesRendered = tipo_niveles.map(it =>
        (<Item key={it.id}>
            <Item.Content verticalAlign='middle'>
                <Item.Header>{it.nombre}</Item.Header>
                <Item.Meta>
                    <span className='cinema'>Nivel: {it.nivel.nombre}</span>
                </Item.Meta>
                <Item.Extra floated='right'>
                    <Button size='mini' content='Borrar' icon='delete' labelPosition='left' onClick={() => {
                        setSelected(it);
                        setConfirmDelete(true);
                    }}/>
                    <Button
                        size='mini'
                        content='Editar'
                        icon='edit'
                        labelPosition='right'
                        onClick={() => {
                            setSelected(it);
                            dispatch(setNivel(Number(it.id_nivel)));
                            setNombre(it.nombre);
                            setModal(true);
                        }}/>
                </Item.Extra>
            </Item.Content>
        </Item>)
    );

    useEffect(() => {
        async function getTipoNiveles() {
            await dispatch(fetchTipoNiveles(token, country.id, nivel));
        }

        getTipoNiveles();
    }, [nivel]);

    useEffect(() => {
        async function getNiveles() {
            await dispatch(fetchNiveles(token, country.id));
        }

        getNiveles();
    }, [country]);

    return (
        <Grid className='no-margin' style={{width: '100%'}}>
            {/*<=<●>=><=<●>=><=<●>=> Confirmar Borrado <=<●>=><=<●>=><=<●>=>*/}
            <Confirm
                open={confirmDelete}
                content='¿Está seguro que quiere eliminar este tipo_nivel?'
                cancelButton={<Button color='red' icon='close' content='Cancelar' inverted/>}
                confirmButton={<Button color='green' icon='checkmark' content='Si, eliminar'/>}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={() => [dispatch(deleteTipoNivel(token, country.id, nivel, selected.id)), setConfirmDelete(false)]}
            />

            <Modal size='tiny' open={modal}>
                <Modal.Header>Editando: {selected.nivel !== undefined ? selected.nivel.nombre : null}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    <label className='truncate subtitle'>Seleccione un nivel</label>
                                    <Select
                                        value={nivel}
                                        onChange={(e, data) => dispatch(setNivel(data.value))}
                                        placeholder='Selecciona el nivel'
                                        options={renderNiveles}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Ingrese un nombre</label>
                                    <Input
                                        onChange={(e) => setNombre(e.target.value)}
                                        placeholder='Dia inicio'
                                        size={'large'}
                                        value={nombre}/>
                                    <Divider className='divider-5' hidden/>
                                    <Button
                                        fluid
                                        color='teal'
                                        loading={loading}
                                        onClick={edit}
                                        content='Guardar'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModal(false);
                            setSelected('');
                            setNombre('');
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Grid.Row
                stretched
                column={2}
                verticalAlign='top'
                className='no-margin'>
                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Crear tipo de nivel</Header>
                    <Grid centered className='no-margin'>
                        <Grid.Row
                            centered
                            className='no-margin scroll1'
                            stretched
                            verticalAlign='middle'>
                            <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                                textAlign='center'>
                                <label className='truncate subtitle'>Seleccione un nivel</label>
                                <Select
                                    value={nivel}
                                    onChange={(e, data) => dispatch(setNivel(data.value))}
                                    placeholder='Selecciona el nivel'
                                    options={renderNiveles}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Ingrese un nombre</label>
                                <Input
                                    onChange={(e) => setNombre(e.target.value)}
                                    placeholder='Nombre'
                                    size={'large'}
                                    value={nombre}/>
                                <Divider className='divider-5' hidden/>
                                <Button
                                    fluid
                                    color='teal'
                                    loading={loading}
                                    onClick={save}
                                    content='Guardar'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>

                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Consultar tipos de niveles</Header>
                    <Grid.Row
                        stretched
                        className='no-margin scroll1'
                        style={{backgroundColor: '#fff', padding: 10, borderRadius: 10}}>
                        <Item.Group divided relaxed style={{width: '100%'}}>
                            {tipo_niveles.length !== 0
                                ? loading
                                    ? Functions.loadingMessage()
                                    : tipoNivelesRendered
                                : loading
                                    ? Functions.loadingMessage()
                                    : Functions.nothingMessage()}
                        </Item.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
}

export default TipoNiveles;
