import {LOADING_TIPOS_CALENDARIOS, SELECTED_TIPO_CALENDARIO, TIPOS_CALENDARIOS} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_TIPOS_CALENDARIOS,
        payload: data
    };
}

export function createTipoCalendario(token, country, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'tipo/calendario/create', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTiposCalendarios(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTiposCalendarios(token, country));
        });
    };
}

export function fetchTiposCalendarios(token, country) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'tipo/calendario/get/' + country, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchTiposCalendariosSuccess(response.data));
        }).catch(error => {
            dispatch(fetchTiposCalendariosFailed());
        });
    };
}

export function updateTipoCalendario(token, country, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'tipo/calendario/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTiposCalendarios(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTiposCalendarios(token, country));
        });
    };
}

export function deleteTipoCalendario(token, country, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'tipo/calendario/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTiposCalendarios(token, country));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTiposCalendarios(token, country));
        });
    };
}

export function fetchTiposCalendariosSuccess(response) {
    return {
        type: TIPOS_CALENDARIOS,
        payload: response
    };
}

export function fetchTiposCalendariosFailed() {
    Functions.alertFail();
    return {
        type: TIPOS_CALENDARIOS,
        payload: []
    };
}

export function setTipoCalendario(TiposCalendarios) {
    return {
        type: SELECTED_TIPO_CALENDARIO,
        payload: TiposCalendarios
    };
}
