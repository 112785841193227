import {LOADING_PLANES, PLANES, SELECTED_PLAN, TEMAS} from '../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_PLANES,
        payload: data
    };
}

export function createPlan(token, country, nivel, tipoNivel, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'planes/create', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchPlanes(token, country, nivel, tipoNivel));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchPlanes(token, country, nivel, tipoNivel));
        });
    };
}

export function updatePlan(token, country, nivel, tipoNivel, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'planes/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchPlanes(token, country, nivel, tipoNivel));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchPlanes(token, country, nivel, tipoNivel));
        });
    };
}

export function deletePlan(token, country, nivel, tipoNivel, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'planes/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchPlanes(token, country, nivel, tipoNivel));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchPlanes(token, country, nivel, tipoNivel));
        });
    };
}

export function fetchPlanes(token, country, nivel, tipoNivel) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'planes/get/' + country + '/' + nivel + '/' + tipoNivel, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchPlanesSuccess(response.data));
        }).catch(error => {
            dispatch(fetchPlanesFailed());
        });
    };
}

export function fetchPlanesSuccess(response) {
    return {
        type: PLANES,
        payload: response
    };
}

export function fetchPlanesFailed() {
    Functions.alertFail();
    return {
        type: PLANES,
        payload: []
    };
}

export function setPlan(Plan) {
    return {
        type: SELECTED_PLAN,
        payload: Plan
    };
}


export function createTema(token, plan, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'planes/tema/create', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTemas(token, plan));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTemas(token, plan));
        });
    };
}

export function updateTema(token, plan, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'planes/tema/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTemas(token, plan));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTemas(token, plan));
        });
    };
}

export function deleteTema(token, plan, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'planes/tema/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchTemas(token, plan));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchTemas(token, plan));
        });
    };
}

export function fetchTemas(token, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'planes/get/temas/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchTemasSuccess(response.data));
        }).catch(error => {
            dispatch(fetchTemasFailed());
        });
    };
}

export function fetchTemasSuccess(response) {
    return {
        type: TEMAS,
        payload: response
    };
}

export function fetchTemasFailed() {
    Functions.alertFail();
    return {
        type: TEMAS,
        payload: []
    };
}
