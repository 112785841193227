import {GRADOS, DELETE_GRADO, LOADING_GRADOS, SELECTED_GRADO, UPDATE_GRADO} from '../../actions/types';

const initialState = {
        loading: false,
        grado: 1,
        grados: []
    }
;

export default function GradosReducer(state = initialState, action) {

    switch (action.type) {
        case GRADOS:
            return {
                ...state,
                grados: action.payload,
                loading: false
            };
        case DELETE_GRADO:
            return {
                ...state,
                grados: action.payload,
                loading: false
            };
        case UPDATE_GRADO:
            return {
                ...state,
                grados: action.payload,
                loading: false
            };
        case SELECTED_GRADO:
            return {
                ...state,
                grado: action.payload
            };
        case LOADING_GRADOS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}