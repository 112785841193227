import React, {useEffect, useState} from 'react';
import {
    Button,
    Confirm,
    Divider,
    Flag,
    Grid,
    Header,
    Icon,
    Input,
    Item,
    Modal,
    Select
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {
    createCalendario,
    deleteCalendario,
    fetchCalendarios,
    updateCalendario
} from '../../redux/actions/CountriesActions/CalendariosActions';
import Functions from '../Global/Functions';
import {fetchTypeEvents, setTypeEvent} from '../../redux/actions/CountriesActions/TypeEventsActions';
import {fetchCiclos, setCiclo} from '../../redux/actions/CountriesActions/CiclosActions';
import {fetchTipoNiveles, setTipoNivel} from '../../redux/actions/CountriesActions/TipoNivelesActions';
import {fetchNiveles, setNivel} from '../../redux/actions/CountriesActions/NivelesActions';
import {fetchTiposCalendarios, setTipoCalendario} from '../../redux/actions/CountriesActions/TipoCalendarioActions';
import TipoCalendario from './TipoCalendario';

function Calendarios() {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [descripcion, setDescripcion] = useState('');
    const [destinatarios, setDestinatarios] = useState('Todos');
    const [fechaFin, setFechaFin] = useState('');
    const [fechaInicio, setFechaInicio] = useState('');
    const [horaFin, setHoraFin] = useState('');
    const [horaInicio, setHoraInicio] = useState('');
    const [lugar, setLugar] = useState('');
    const [modal, setModal] = useState(false);
    const [modalTipoCalendario, setModalTipoCalendario] = useState(false);
    const [selected, setSelected] = useState('');
    const [tipoAviso, setTipoAviso] = useState('1');
    const [tipoDia, setTipoDia] = useState('1');
    const [tipoEvento, setTipoEvento] = useState('Todos');
    const [titulo, setTitulo] = useState('');
    const calendarios = useSelector(state => state.calendarios.calendarios);
    const ciclo = useSelector(state => state.ciclos.ciclo);
    const ciclos = useSelector(state => state.ciclos.ciclos);
    const country = useSelector(state => state.countries.country);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.calendarios.loading);
    const nivel = useSelector(state => state.niveles.nivel);
    const niveles = useSelector(state => state.niveles.niveles);
    const tipoNivel = useSelector(state => state.tipo_niveles.tipo_nivel);
    const tipoNiveles = useSelector(state => state.tipo_niveles.tipo_niveles);
    const token = useSelector(state => state.root.token);
    const typeEvent = useSelector(state => state.type_events.type_event);
    const typesEvents = useSelector(state => state.type_events.type_events);
    const tipoCalendario = useSelector(state => state.tipos_calendarios.tipo_calendario);
    const tiposCalendarios = useSelector(state => state.tipos_calendarios.tipos_calendarios);
    const renderTypesEvents = typesEvents.map(it => (
        {key: it.id, value: it.id, text: it.name}
    ));
    const renderNiveles = niveles.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const renderCiclos = ciclos.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const renderTipoNiveles = tipoNiveles.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const renderTipoCalendarios = tiposCalendarios.map(it => (
        {key: it.id, value: it.id, text: it.nombre}
    ));
    const save = () => {
        if (tipoCalendario && tipoEvento && titulo && nivel && tipoNivel && ciclo && titulo && fechaInicio && fechaFin) {
            let fd = JSON.stringify({
                id_pais: country.id,
                nivel: nivel,
                tipo: tipoCalendario,
                tipo_nivel: tipoNivel,
                titulo: titulo.toUpperCase(),
                descripcion: descripcion.toUpperCase(),
                lugar: lugar.toUpperCase(),
                fecha_inicio: fechaInicio,
                fecha_fin: fechaFin,
                hora_inicio: horaInicio,
                hora_fin: horaFin,
                id_ciclo: ciclo,
                user_id: '0',
                tipo_evento: typeEvent,
                destinatarios: destinatarios,
                tipo_dia: tipoDia,
                tipo_aviso: tipoAviso
            });
            dispatch(createCalendario(token, country.id, nivel, tipoNivel, ciclo, fd));
            setTitulo('');
            setDescripcion('');
            setLugar('');
            setFechaInicio('');
            setFechaFin('');
            setHoraInicio('');
            setHoraFin('');
            setTipoEvento('');
            setDestinatarios('Todos');
            setTipoDia('');
            setTipoAviso('');
        } else {
            Functions.alertWarning();
        }
    };

    const edit = () => {
        if (tipoCalendario && tipoEvento && titulo && nivel && tipoNivel && ciclo && titulo && fechaInicio && fechaFin) {
            let fd = JSON.stringify({
                id_pais: country.id,
                nivel: nivel,
                tipo: tipoCalendario,
                tipo_nivel: tipoNivel,
                titulo: titulo.toUpperCase(),
                descripcion: descripcion.toUpperCase(),
                lugar: lugar.toUpperCase(),
                fecha_inicio: fechaInicio,
                fecha_fin: fechaFin,
                hora_inicio: horaInicio,
                hora_fin: horaFin,
                id_ciclo: ciclo,
                user_id: '0',
                tipo_evento: typeEvent,
                destinatarios: destinatarios,
                tipo_dia: tipoDia,
                tipo_aviso: tipoAviso
            });
            dispatch(updateCalendario(token, country.id, nivel, tipoNivel, ciclo, fd, selected.id));
            setTitulo('');
            setDescripcion('');
            setLugar('');
            setFechaInicio('');
            setFechaFin('');
            setHoraInicio('');
            setHoraFin('');
            setTipoEvento('');
            setDestinatarios('Todos');
            setTipoDia('');
            setTipoAviso('');
        } else {
            Functions.alertWarning();
        }
    };

    const calendariosRendered = calendarios.map(it =>
        (<Item key={it.id}>
            <Flag name={it.bandera}/>
            <Item.Content verticalAlign='middle'>
                <Item.Header>{it.titulo}</Item.Header>
                <br/>
                <Item.Meta>{it.descripcion}</Item.Meta>
                <br/>
                <Item.Meta>{it.fecha_inicio + ' - ' + it.fecha_fin}</Item.Meta>
                <Item.Extra floated='right'>
                    <Button size='mini' content='Borrar' icon='delete' labelPosition='left' onClick={() => {
                        setSelected(it.id);
                        setConfirmDelete(true);
                    }}/>
                    <Button
                        size='mini'
                        content='Editar'
                        icon='edit'
                        labelPosition='right'
                        onClick={() => {
                            setSelected(it);
                            dispatch(setTipoCalendario(Number(it.tipo)));
                            dispatch(setTypeEvent(Number(it.tipo_evento)));
                            setTitulo(it.titulo);
                            setDescripcion(it.descripcion);
                            setLugar(it.lugar);
                            setFechaInicio(it.fecha_inicio);
                            setFechaFin(it.fecha_fin);
                            setHoraInicio(it.hora_inicio);
                            setHoraFin(it.hora_fin);
                            setTipoEvento(it.tipo_evento);
                            setDestinatarios(it.destinatarios);
                            setTipoDia(it.tipo_dia);
                            setTipoAviso(it.tipo_aviso);
                            setModal(true);
                        }}/>
                </Item.Extra>
            </Item.Content>
        </Item>)
    );

    useEffect(() => {
        async function getTypeEvents() {
            await dispatch(fetchTypeEvents(token, country.id));
        }

        getTypeEvents();
    }, [country]);

    useEffect(() => {
        async function getCalendarios() {
            await dispatch(fetchCalendarios(token, country.id, nivel, tipoNivel, ciclo));
        }

        getCalendarios();
    }, [country, nivel, tipoNivel, ciclo]);

    useEffect(() => {
        async function getNiveles() {
            await dispatch(fetchNiveles(token, country.id));
        }

        getNiveles();
    }, [country]);

    useEffect(() => {
        async function getTipoNiveles() {
            await dispatch(fetchTipoNiveles(token, country.id, nivel));
        }

        getTipoNiveles();
    }, [nivel]);


    useEffect(() => {
        async function getCiclos() {
            await dispatch(fetchCiclos(token, country.id, nivel));
        }

        getCiclos();
    }, [nivel]);

    useEffect(() => {
        async function asd() {
            await dispatch(fetchTiposCalendarios(token, country.id));
        }

        asd();
    }, [country]);


    return (
        <Grid className='no-margin' style={{width: '100%'}}>
            {/*<=<●>=><=<●>=><=<●>=> Confirmar Borrado <=<●>=><=<●>=><=<●>=>*/}
            <Confirm
                open={confirmDelete}
                content='¿Está seguro que quiere eliminar este grado?'
                cancelButton={<Button color='red' icon='close' content='Cancelar' inverted/>}
                confirmButton={<Button color='green' icon='checkmark' content='Si, eliminar'/>}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={() => [dispatch(deleteCalendario(token.country.id, nivel, tipoNivel, ciclo, selected)), setConfirmDelete(false)]}
            />

            <Modal size='tiny' open={modal}>
                <Modal.Header>Editando: {selected.nombre}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered className='no-margin'>
                            <Grid.Row
                                centered
                                className='no-margin scroll1'
                                stretched
                                verticalAlign='middle'>
                                <Grid.Column
                                    computer={16}
                                    mobile={16}
                                    tablet={16}
                                    textAlign='center'>
                                    <label className='truncate subtitle'>Seleccione un tipo de calendario</label>
                                    <Button fluid as='div' labelPosition='left'>
                                        <Select
                                            fluid
                                            attached='right'
                                            value={tipoCalendario}
                                            onChange={(e, data) => dispatch(setTipoCalendario(data.value))}
                                            placeholder='Selecciona el tipo'
                                            options={renderTipoCalendarios}/>
                                        <Button onClick={() => setModalTipoCalendario(true)} icon>
                                            <Icon name='plus'/>
                                        </Button>
                                    </Button>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione un tipo de evento</label>
                                    <Select
                                        onChange={(e, data) => dispatch(setTypeEvent(data.value))}
                                        options={renderTypesEvents}
                                        placeholder='Selecciona el tipo de evento'
                                        value={typeEvent}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione un nivel</label>
                                    <Select
                                        value={nivel}
                                        onChange={(e, data) => dispatch(setNivel(data.value))}
                                        placeholder='Selecciona el nivel'
                                        options={renderNiveles}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione un tipo de nivel</label>
                                    <Select
                                        value={tipoNivel}
                                        onChange={(e, data) => dispatch(setTipoNivel(data.value))}
                                        placeholder='Selecciona el tipo de nivel'
                                        options={renderTipoNiveles}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione un ciclo</label>
                                    <Select
                                        value={ciclo}
                                        onChange={(e, data) => dispatch(setCiclo(data.value))}
                                        placeholder='Selecciona el ciclo'
                                        options={renderCiclos}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Ingrese un titulo</label>
                                    <Input
                                        className='uppercase'
                                        fluid
                                        onChange={(e) => setTitulo(e.target.value)}
                                        placeholder='Titulo del evento'
                                        size={'large'}
                                        value={titulo}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Ingrese una descripción</label>
                                    <Input
                                        className='uppercase'
                                        fluid
                                        onChange={(e) => setDescripcion(e.target.value)}
                                        placeholder='Descripción del evento'
                                        size={'large'}
                                        value={descripcion}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Ingrese un lugar</label>
                                    <Input
                                        className='uppercase'
                                        fluid
                                        onChange={(e) => setLugar(e.target.value)}
                                        placeholder='Lugar del evento'
                                        size={'large'}
                                        value={lugar}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione una fecha de inicio</label>
                                    <Input
                                        className='uppercase'
                                        fluid
                                        onChange={(e) => setFechaInicio(e.target.value)}
                                        placeholder='Fecha de inicio'
                                        size={'large'}
                                        type='date'
                                        value={fechaInicio}/>
                                    <Divider className='divider-5' hidden/>
                                    <label className='truncate subtitle'>Seleccione una fecha de fin</label>
                                    <Input
                                        className='uppercase'
                                        fluid
                                        onChange={(e) => setFechaFin(e.target.value)}
                                        placeholder='Fecha de fin'
                                        size={'large'}
                                        type='date'
                                        value={fechaFin}/>
                                    <Divider className='divider-5' hidden/>
                                    <Button
                                        color='teal'
                                        content='Guardar'
                                        fluid
                                        loading={loading}
                                        onClick={edit}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setSelected('');
                            setTitulo('');
                            setDescripcion('');
                            setLugar('');
                            setFechaInicio('');
                            setFechaFin('');
                            setHoraInicio('');
                            setHoraFin('');
                            setTipoEvento('');
                            setDestinatarios('');
                            setTipoDia('');
                            setTipoAviso('');
                            setModal(false);
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal size='large' open={modalTipoCalendario}>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <TipoCalendario/>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        onClick={() => {
                            setModalTipoCalendario(false);
                        }}
                        inverted>
                        <Icon name='close'/> Cerrar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Grid.Row
                stretched
                column={2}
                verticalAlign='top'
                className='no-margin'>
                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Crear evento</Header>
                    <Grid centered className='no-margin'>
                        <Grid.Row
                            centered
                            className='no-margin scroll1'
                            stretched
                            verticalAlign='middle'>
                            <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                                textAlign='center'>
                                <label className='truncate subtitle'>Seleccione un tipo de calendario</label>
                                <Button fluid as='div' labelPosition='left'>
                                    <Select
                                        fluid
                                        attached='right'
                                        value={tipoCalendario}
                                        onChange={(e, data) => dispatch(setTipoCalendario(data.value))}
                                        placeholder='Selecciona el tipo'
                                        options={renderTipoCalendarios}/>
                                    <Button onClick={() => setModalTipoCalendario(true)} icon>
                                        <Icon name='plus'/>
                                    </Button>
                                </Button>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un tipo de evento</label>
                                <Select
                                    value={typeEvent}
                                    onChange={(e, data) => dispatch(setTypeEvent(data.value))}
                                    placeholder='Selecciona el nivel'
                                    options={renderTypesEvents}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un nivel</label>
                                <Select
                                    value={nivel}
                                    onChange={(e, data) => dispatch(setNivel(data.value))}
                                    placeholder='Selecciona el nivel'
                                    options={renderNiveles}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un tipo de nivel</label>
                                <Select
                                    value={tipoNivel}
                                    onChange={(e, data) => dispatch(setTipoNivel(data.value))}
                                    placeholder='Selecciona el tipo nivel'
                                    options={renderTipoNiveles}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un ciclo</label>
                                <Select
                                    value={ciclo}
                                    onChange={(e, data) => dispatch(setCiclo(data.value))}
                                    placeholder='Selecciona el ciclo'
                                    options={renderCiclos}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Ingrese el titulo</label>
                                <Input
                                    className='uppercase'
                                    fluid
                                    onChange={(e) => setTitulo(e.target.value)}
                                    placeholder='Titulo del evento'
                                    size={'large'}
                                    value={titulo}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Ingrese una descripción</label>
                                <Input
                                    className='uppercase'
                                    fluid
                                    onChange={(e) => setDescripcion(e.target.value)}
                                    placeholder='Descripción del evento'
                                    size={'large'}
                                    value={descripcion}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Ingrese un lugar</label>
                                <Input
                                    className='uppercase'
                                    fluid
                                    onChange={(e) => setLugar(e.target.value)}
                                    placeholder='Lugar del evento'
                                    size={'large'}
                                    value={lugar}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un fecha de inicio</label>
                                <Input
                                    className='uppercase'
                                    fluid
                                    onChange={(e) => setFechaInicio(e.target.value)}
                                    placeholder='Fecha de inicio'
                                    size={'large'}
                                    type='date'
                                    value={fechaInicio}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Seleccione un fecha de fin</label>
                                <Input
                                    className='uppercase'
                                    fluid
                                    onChange={(e) => setFechaFin(e.target.value)}
                                    placeholder='Fecha de fin'
                                    size={'large'}
                                    type='date'
                                    value={fechaFin}/>
                                <Divider className='divider-5' hidden/>
                                <Button
                                    fluid
                                    color='teal'
                                    loading={loading}
                                    onClick={save}
                                    content='Guardar'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>

                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Consultar eventos</Header>
                    <Grid.Row
                        stretched
                        className='no-margin'
                        style={{backgroundColor: '#fff', padding: 10, borderRadius: 10}}>
                        <Divider className='divider-5' hidden/>
                        <Item.Group divided relaxed style={{width: '100%'}}>
                            {calendarios.length !== 0
                                ? loading
                                    ? Functions.loadingMessage()
                                    : calendariosRendered
                                : loading
                                    ? Functions.loadingMessage()
                                    : Functions.nothingMessage()}
                        </Item.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
}

export default Calendarios;
