import {NIVELES, DELETE_NIVEL, LOADING_NIVELES, SELECTED_NIVEL, UPDATE_NIVEL} from '../../actions/types';

const initialState = {
        loading: false,
        nivel: 1,
        niveles: []
    }
;

export default function NivelesReducer(state = initialState, action) {

    switch (action.type) {
        case NIVELES:
            return {
                ...state,
                niveles: action.payload,
                loading: false
            };
        case DELETE_NIVEL:
            return {
                ...state,
                niveles: action.payload,
                loading: false
            };
        case UPDATE_NIVEL:
            return {
                ...state,
                niveles: action.payload,
                loading: false
            };
        case SELECTED_NIVEL:
            return {
                ...state,
                nivel: action.payload
            };
        case LOADING_NIVELES:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}