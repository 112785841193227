import React from 'react';
import {Grid, Label} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';

function UserInformation() {
    const dispatch = useDispatch();
    const userRole = useSelector(state => state.root.userRole);
    const userName = useSelector(state => state.root.userName);
    const userPicture = useSelector(state => state.root.userPicture);
    return (
        <Grid className='no-margin'>
            <Grid.Row
                centered
                className='no-margin'
                column={3}>
                <Label color='blue' image>
                    <img alt={''} src={userPicture}/>
                    {userName}
                    <Label.Detail>{userRole}</Label.Detail>
                </Label>
            </Grid.Row>
        </Grid>
    );
}

export default UserInformation;
