import React, {useEffect, useState} from 'react';
import {Button, Divider, Flag, Grid, Header, Icon, Image, Modal, Popup, Segment} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {AppEnv} from '../env';
import Paises from '../Configuracion/Paises';
import Niveles from '../Configuracion/Niveles';
import Ciclos from '../Configuracion/Ciclos';
import Calendarios from '../Configuracion/Calendarios';
import Materias from '../Configuracion/Materias';
import Grados from '../Configuracion/Grados';
import PlanEstudios from '../Configuracion/PlanEstudios';
import Alias from '../Configuracion/Alias';
import {fetchCountries, setCountry} from '../../redux/actions/CountriesActions/CountriesActions';
import TypeEvents from './TypeEvents';
import TipoNiveles from './TiposNiveles';
import Globals from './Globals';

function MenuConfiguracion() {
    const userName = useSelector(state => state.root.userName);
    const countries = useSelector(state => state.countries.countries);
    const country = useSelector(state => state.countries.country);
    const token = useSelector(state => state.root.token);
    const dispatch = useDispatch();
    const [selectedMenu, setSelectedMenu] = useState('');
    const [modal, setModal] = useState(false);
    const menus = [
        {nombre: 'Niveles', url: 'https://img.icons8.com/dusk/80/000000/courses.png'},
        {nombre: 'Tipo por nivel', url: 'https://img.icons8.com/dusk/80/000000/courses.png'},
        {nombre: 'Ciclos', url: 'https://img.icons8.com/dusk/80/000000/date-span.png'},
        {nombre: 'Grados', url: 'https://img.icons8.com/dusk/80/000000/google-groups.png'},
        {nombre: 'Materias', url: 'https://img.icons8.com/dusk/80/000000/books.png'},
        {nombre: 'Eventos', url: 'https://img.icons8.com/color/80/000000/event-accepted.png'},
        {nombre: 'Calendarios', url: 'https://img.icons8.com/dusk/80/000000/today.png'},
        {nombre: 'Globals', url: 'https://img.icons8.com/color/80/000000/gear.png'},
        {nombre: 'Plan de estudios', url: 'https://img.icons8.com/color/80/000000/book.png'}
    ];
    const inicio = <Grid className='no-margin'>
        <Header as='h2'>
            <Header.Content>
                <Header.Subheader>Para continuar, selecciona una opción del menú que está arriba</Header.Subheader>
            </Header.Content>
        </Header>
    </Grid>;

    const countriesRendered = countries.map(it =>
        (<Button
            active={country.id === it.id}
            onClick={() => dispatch(setCountry(it))}
            style={{padding: 5}}
            key={it.id}>
            <Flag name={it.bandera}/>
        </Button>));

    const menusRendered = menus.map(it =>
        <Popup
            key={it.nombre}
            trigger={<Grid.Column
                className='borderRadius'
                computer={2}
                mobile={4}
                style={{padding: 5}}
                tablet={3}
                textAlign='center'>
                <Segment textAlign='center'
                         style={{backgroundColor: selectedMenu === it.nombre ? AppEnv.mainColor : null, padding: 8}}
                         onClick={() => setSelectedMenu(it.nombre)}>
                    <Image src={it.url} size='mini' centered/>
                    <Grid className='no-margin'>
                        <Grid.Row
                            centered
                            style={{padding: 3, color: selectedMenu === it.nombre ? '#fff' : null}}
                            className='no-margin'
                            only='tablet computer'>
                            <label className='truncate'>{it.nombre}</label>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Grid.Column>}
            content={it.nombre}
            inverted
        />
    );

    useEffect(() => {
        async function asd() {
            await dispatch(fetchCountries(token));
        }

        asd();
    }, []);
    return (
        <Grid centered className='no-margin'>
            <Grid.Row className='no-margin'>
                <Button.Group basic widths={countries.length + 1}>
                    {countriesRendered}
                    <Modal open={modal} trigger={<Button onClick={() => setModal(true)} icon={'plus'}/>}>
                        <Modal.Header>Agregar país</Modal.Header>
                        <Modal.Content scrolling>
                            <Modal.Description>
                                <Paises/>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='red' onClick={() => setModal(false)} inverted>
                                <Icon name='close'/> Cerrar
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Button.Group>
            </Grid.Row>
            <Grid.Row className='no-margin'>
                {menusRendered}
            </Grid.Row>
            <Divider style={{margin: 0}}/>
            <Grid.Row className='no-margin' style={{paddingBottom: 0}}>
                {selectedMenu === 'Niveles'
                    ? <Niveles/>
                    : selectedMenu === 'Ciclos'
                        ? <Ciclos/>
                        : selectedMenu === 'Calendarios'
                            ? <Calendarios/>
                            : selectedMenu === 'Materias'
                                ? <Materias/>
                                : selectedMenu === 'Grados'
                                    ? <Grados/>
                                    : selectedMenu === 'Eventos'
                                        ? <TypeEvents/>
                                        : selectedMenu === 'Alias'
                                            ? <Alias/>
                                            : selectedMenu === 'Tipo por nivel'
                                                ? <TipoNiveles/>
                                                : selectedMenu === 'Globals'
                                                    ? <Globals/>
                                                    : selectedMenu === 'Plan de estudios'
                                                    ? <PlanEstudios/>
                                                    : inicio}

            </Grid.Row>
        </Grid>);
}

export default MenuConfiguracion;
