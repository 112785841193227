import React, {useEffect, useState} from 'react';
import {Button, Confirm, Divider, Flag, Grid, Header, Input, Item} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {createAlias, deleteAlias, fetchAlias} from '../../redux/actions/CountriesActions/AliasActions';
import Functions from '../Global/Functions';

function Alias() {
    const [nombre, setNombre] = useState('');
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selected, setSelected] = useState('');
    const alias = useSelector(state => state.alias.alias);
    const loading = useSelector(state => state.alias.loading);
    const country = useSelector(state => state.countries.country);
    const token = useSelector(state => state.root.token);
    const dispatch = useDispatch();
    const save = () => {
        if (nombre && country) {
            let fd = JSON.stringify({
                id_pais: country.id,
                nombre: nombre.toUpperCase(),
            });
            dispatch(createAlias(token, fd));
            setNombre('');
        } else {
            Functions.alertWarning();
        }
    };
    const aliasRendered = alias.map(it =>
        (<Item key={it.id}>
            <Flag name={it.bandera}/>
            <Item.Content verticalAlign='middle'>
                <Item.Header>{it.nombre}</Item.Header>
                <Item.Extra floated='right'>
                    <Button size='mini' content='Borrar' icon='delete' labelPosition='left' onClick={() => {
                        setSelected(it.id);
                        setConfirmDelete(true);
                    }}/>
                    <Button size='mini' content='Editar' icon='edit' labelPosition='right'/>
                </Item.Extra>
            </Item.Content>
        </Item>)
    );

    useEffect(() => {
        async function asd() {
            await dispatch(fetchAlias(token));
        }

        asd();
    }, []);

    return (
        <Grid className='no-margin' style={{width: '100%'}}>
            {/*<=<●>=><=<●>=><=<●>=> Confirmar Borrado <=<●>=><=<●>=><=<●>=>*/}
            <Confirm
                open={confirmDelete}
                content='¿Está seguro que quiere eliminar este país?'
                cancelButton={<Button color='red' icon='close' content='Cancelar' inverted/>}
                confirmButton={<Button color='green' icon='checkmark' content='Si, eliminar'/>}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={() => [dispatch(deleteAlias(token, selected)), setConfirmDelete(false)]}
            />
            <Grid.Row
                stretched
                column={2}
                verticalAlign='top'
                className='no-margin'>
                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Crear país</Header>
                    <Grid centered className='no-margin'>
                        <Grid.Row
                            centered
                            className='no-margin'
                            stretched
                            verticalAlign='middle'>
                            <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                                textAlign='center'>
                                <Input
                                    fluid
                                    label={{icon: 'at'}}
                                    labelPosition='left corner'
                                    onChange={(e) => setNombre(e.target.value)}
                                    placeholder='Nombre de la alias'
                                    size={'large'}
                                    style={{textTransform: 'uppercase'}}
                                    value={nombre}/>
                                <Divider className='divider-5' hidden/>
                                <Button
                                    fluid
                                    color='teal'
                                    loading={loading}
                                    onClick={save}
                                    content='Guardar'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>

                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Consultar países</Header>
                    <Grid.Row
                        stretched
                        className='no-margin'
                        style={{backgroundColor: '#fff', padding: 10, borderRadius: 10}}>
                        <Item.Group divided relaxed style={{width: '100%'}}>
                            {aliasRendered}
                        </Item.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
}

export default Alias;
