import React, {useEffect} from 'react';
import {Button, Card, Checkbox, Divider, Grid, Header, Icon, Input} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchGlobals,
    setAliasCiudad,
    setAliasColonia,
    setAliasEstado,
    setAliasIdCiudadano,
    setAliasIdEscuela,
    setApellidoMaterno,
    setCodigoPostal,
    setDataBase,
    setEstado,
    setIdCiudadano,
    setIdEscuela,
    updateGlobals
} from '../../redux/actions/CountriesActions/GlobalsActions';

function Globals() {
    const token = useSelector(state => state.root.token);
    const country = useSelector(state => state.countries.country);
    const dataBase = useSelector(state => state.globals.data_base);
    const apellidoMaterno = useSelector(state => state.globals.apellido_materno);
    const idCiudadano = useSelector(state => state.globals.id_ciudadano);
    const aliasIdCiudadano = useSelector(state => state.globals.alias_id_ciudadano);
    const idEscuela = useSelector(state => state.globals.id_escuela);
    const aliasIdEscuela = useSelector(state => state.globals.alias_id_escuela);
    const codigoPostal = useSelector(state => state.globals.codigo_postal);
    const aliasColonia = useSelector(state => state.globals.alias_colonia);
    const aliasCiudad = useSelector(state => state.globals.alias_ciudad);
    const estado = useSelector(state => state.globals.estado);
    const aliasEstado = useSelector(state => state.globals.alias_estado);

    const loading = useSelector(state => state.globals.loading);
    const dispatch = useDispatch();

    const save = () => {
        let fd = JSON.stringify([
            {id_pais: country.id, option: 'data_base', value: dataBase ? dataBase : false},
            {id_pais: country.id, option: 'apellido_materno', value: apellidoMaterno ? apellidoMaterno : false},
            {id_pais: country.id, option: 'id_ciudadano', value: idCiudadano ? idCiudadano : false},
            {id_pais: country.id, option: 'alias_id_ciudadano', value: aliasIdCiudadano ? aliasIdCiudadano : ''},
            {id_pais: country.id, option: 'id_escuela', value: idEscuela ? idEscuela : ''},
            {id_pais: country.id, option: 'alias_id_escuela', value: aliasIdEscuela ? aliasIdEscuela : ''},
            {id_pais: country.id, option: 'codigo_postal', value: codigoPostal ? codigoPostal : ''},
            {id_pais: country.id, option: 'alias_colonia', value: aliasColonia ? aliasColonia : ''},
            {id_pais: country.id, option: 'alias_ciudad', value: aliasCiudad ? aliasCiudad : ''},
            {id_pais: country.id, option: 'alias_estado', value: aliasEstado ? aliasEstado : ''},
            {id_pais: country.id, option: 'estado', value: estado ? estado : ''}
        ]);
        dispatch(updateGlobals(token, fd, country.id));
    };

    useEffect(() => {
        dispatch(fetchGlobals(token, country.id));
    }, [country]);

    return (
        <Grid className='no-margin' style={{width: '100%'}}>
            <Grid.Row
                stretched
                style={{padding: 0}}
                column={2}
                verticalAlign='middle'
                className='no-margin'>
                <Grid.Column
                    computer={12}
                    mobile={16}
                    tablet={12}
                    textAlign='center'>
                    <Header as='h3'>
                        <Icon name='settings'/>
                        <Header.Content>
                            Configuración global para {country.nombre}
                        </Header.Content>
                    </Header>
                </Grid.Column>
                <Grid.Column
                    computer={4}
                    mobile={16}
                    tablet={4}
                    textAlign='center'>
                    <Button
                        color='teal'
                        loading={loading}
                        onClick={save}
                        content='Guardar'
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row
                stretched
                verticalAlign='top'
                className='no-margin scroll1'>
                <Card.Group centered>
                    <Card>
                        <Card.Content>
                            <Card.Header>Escuelas</Card.Header>
                            <Card.Description>
                                <Checkbox toggle checked={dataBase === '1'}
                                          onChange={(e, data) => dispatch(setDataBase(data.checked ? '1' : '0'))}
                                          label={'Existe una base de datos de escuelas de ' + country.nombre}/>
                            </Card.Description>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>Id de escuela</Card.Header>
                            <Card.Description>
                                <Checkbox toggle checked={idEscuela === '1'}
                                          onChange={(e, data) => dispatch(setIdEscuela(data.checked ? '1' : '0'))}
                                          label={'Existe un id de escuela en ' + country.nombre}/>
                                <Divider className='divider-5' hidden/>
                                <Input onChange={(e) => dispatch(setAliasIdEscuela(e.target.value))}
                                       value={aliasIdEscuela} label='Alias' fluid/>
                            </Card.Description>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>Datos de contacto</Card.Header>
                            <Card.Description>
                                <Checkbox onChange={(e, data) => dispatch(setApellidoMaterno(data.checked ? '1' : '0'))}
                                          toggle checked={apellidoMaterno === '1'} label={'Apellido Materno'}/>
                                <Divider className='divider-5' hidden/>
                                <Checkbox onChange={(e, data) => dispatch(setCodigoPostal(data.checked ? '1' : '0'))}
                                          toggle checked={codigoPostal === '1'} label={'Código postal'}/>
                                <Divider className='divider-5' hidden/>
                                <Checkbox toggle checked={idCiudadano === '1'}
                                          onChange={(e, data) => dispatch(setIdCiudadano(data.checked ? '1' : '0'))}
                                          label={'Identificador de ciudadanía'}/>
                                <Divider className='divider-5' hidden/>
                                <Input onChange={(e) => dispatch(setAliasIdCiudadano(e.target.value))}
                                       value={aliasIdCiudadano} label='Alias' fluid/>
                                <Divider className='divider-5' hidden/>
                                <Input onChange={(e) => dispatch(setAliasColonia(e.target.value))}
                                       value={aliasColonia} label='Alias de Colonia' fluid/>
                                <Divider className='divider-5' hidden/>
                                <Input onChange={(e) => dispatch(setAliasCiudad(e.target.value))}
                                       value={aliasCiudad} label='Alias de Ciudad' fluid/>
                                <Divider className='divider-5' hidden/>
                                <Checkbox onChange={(e, data) => dispatch(setEstado(data.checked ? '1' : '0'))}
                                          toggle checked={estado === '1'} label={'Estado'}/>
                                <Divider className='divider-5' hidden/>
                                <Input onChange={(e) => dispatch(setAliasEstado(e.target.value))}
                                       value={aliasEstado} label='Alias de Estado' fluid/>
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </Card.Group>
            </Grid.Row>
        </Grid>);
}

export default Globals;
