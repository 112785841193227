import React, {useEffect, useState} from 'react';
import {Button, Confirm, Divider, Flag, Grid, Header, Input, Item} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {createCountry, deleteCountry, fetchCountries} from '../../redux/actions/CountriesActions/CountriesActions';
import Functions from '../Global/Functions';

function Paises() {
    const [nombre, setNombre] = useState('');
    const [cod, setCod] = useState('');
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selected, setSelected] = useState('');
    const countries = useSelector(state => state.countries.countries);
    const loading = useSelector(state => state.countries.loading);
    const token = useSelector(state => state.root.token);
    const dispatch = useDispatch();
    const save = () => {
        if (nombre && cod) {
            let fd = JSON.stringify({
                code: cod.toUpperCase(),
                nombre: nombre.toUpperCase(),
                bandera: cod.toLowerCase()
            });
            dispatch(createCountry(token, fd));
            setNombre('');
            setCod('');
        } else {
            Functions.alertWarning();
        }
    };
    const countriesRendered = countries.map(it =>
        (<Item key={it.id}>
            <Flag name={it.bandera}/>
            <Item.Content verticalAlign='middle'>
                <Item.Header>{it.nombre}</Item.Header>
                <Item.Meta>
                    <span className='cinema'>Código de país: {it.code}</span>
                </Item.Meta>
                <Item.Extra floated='right'>
                    <Button size='mini' content='Borrar' icon='delete' labelPosition='left' onClick={() => {
                        setSelected(it.id);
                        setConfirmDelete(true);
                    }}/>
                    <Button size='mini' content='Editar' icon='edit' labelPosition='right'/>
                </Item.Extra>
            </Item.Content>
        </Item>)
    );

    useEffect(() => {
        async function asd() {
            await dispatch(fetchCountries(token));
        }

        asd();
    }, []);

    return (
        <Grid className='no-margin' style={{width: '100%'}}>
            {/*<=<●>=><=<●>=><=<●>=> Confirmar Borrado <=<●>=><=<●>=><=<●>=>*/}
            <Confirm
                open={confirmDelete}
                content='¿Está seguro que quiere eliminar este país?'
                cancelButton={<Button color='red' icon='close' content='Cancelar' inverted/>}
                confirmButton={<Button color='green' icon='checkmark' content='Si, eliminar'/>}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={() => [dispatch(deleteCountry(token, selected)), setConfirmDelete(false)]}
            />
            <Grid.Row
                stretched
                column={2}
                verticalAlign='top'
                className='no-margin'>
                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Crear país</Header>
                    <Grid centered className='no-margin'>
                        <Grid.Row
                            centered
                            className='no-margin scroll1'
                            stretched
                            verticalAlign='middle'>
                            <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                                textAlign='center'>
                                <label className='truncate subtitle'>Ingrese un código de país</label>
                                <Input
                                    fluid
                                    onChange={(e) => setCod(e.target.value)}
                                    placeholder='Código de país'
                                    size={'large'}
                                    style={{textTransform: 'uppercase'}}
                                    value={cod}/>
                                <Divider className='divider-5' hidden/>
                                <label className='truncate subtitle'>Ingrese un nombre</label>
                                <Input
                                    fluid
                                    onChange={(e) => setNombre(e.target.value)}
                                    placeholder='Nombre del país'
                                    size={'large'}
                                    style={{textTransform: 'uppercase'}}
                                    value={nombre}/>
                                <Divider className='divider-5' hidden/>
                                <Button
                                    fluid
                                    color='teal'
                                    loading={loading}
                                    onClick={save}
                                    content='Guardar'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>

                <Grid.Column
                    computer={8}
                    mobile={16}
                    tablet={8}
                    textAlign='center'>
                    <Header as='h2'>Consultar países</Header>
                    <Grid.Row
                        stretched
                        className='no-margin scroll1'
                        style={{backgroundColor: '#fff', padding: 10, borderRadius: 10}}>
                        <Item.Group divided relaxed style={{width: '100%'}}>
                            {countriesRendered}
                        </Item.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
}

export default Paises;
