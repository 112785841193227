import {
    DELETE_TIPO_CALENDARIO,
    LOADING_TIPOS_CALENDARIOS,
    SELECTED_TIPO_CALENDARIO,
    TIPOS_CALENDARIOS,
    UPDATE_TIPO_CALENDARIO
} from '../../actions/types';

const initialState = {
        loading: false,
        tipo_calendario: 1,
        tipos_calendarios: []
    }
;

export default function NivelesReducer(state = initialState, action) {

    switch (action.type) {
        case TIPOS_CALENDARIOS:
            return {
                ...state,
                tipos_calendarios: action.payload,
                loading: false
            };
        case DELETE_TIPO_CALENDARIO:
            return {
                ...state,
                tipos_calendarios: action.payload,
                loading: false
            };
        case UPDATE_TIPO_CALENDARIO:
            return {
                ...state,
                tipos_calendarios: action.payload,
                loading: false
            };
        case SELECTED_TIPO_CALENDARIO:
            return {
                ...state,
                tipo_calendario: action.payload
            };
        case LOADING_TIPOS_CALENDARIOS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
