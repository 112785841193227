import {LOADING_PARTNERS, PARTNERS} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';
import {fetchCountries} from "../CountriesActions/CountriesActions";

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_PARTNERS,
        payload: data
    };
}

export function createCountry(token, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'partners/new', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchCountries(token));
        }).catch(error => {
            // dispatch(fetchPartnersFailed());
        });
    };
}

export function fetchPartners(token) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'partner/get', {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchPartnersSuccess(response.data));
        }).catch(error => {
            dispatch(fetchPartnersFailed());
        });
    };
}

export function acceptPartner(token, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'partner/accept/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchPartners(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchPartners(token));
        });
    };
}

export function declinePartner(token, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'partner/decline/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchPartners(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchPartners(token));
        });
    };
}

export function updatePartner(token, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'partner/update/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchCountries(token));
        }).catch(error => {
            // dispatch(fetchPartnersFailed());
        });
    };
}

export function deletePartner(token, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'partner/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchCountries(token));
        }).catch(error => {
            dispatch(fetchPartnersFailed());
        });
    };
}

export function fetchPartnersSuccess(response) {
    return {
        type: PARTNERS,
        payload: response
    };
}

export function fetchPartnersFailed() {
    Functions.alertFail();
    return {
        type: PARTNERS,
        payload: []
    };
}