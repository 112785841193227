import {MATERIAS, DELETE_MATERIA, LOADING_MATERIAS, SELECTED_MATERIA, UPDATE_MATERIA} from '../../actions/types';

const initialState = {
        loading: false,
        materia: 1,
        materias: []
    }
;

export default function NivelesReducer(state = initialState, action) {

    switch (action.type) {
        case MATERIAS:
            return {
                ...state,
                materias: action.payload,
                loading: false
            };
        case DELETE_MATERIA:
            return {
                ...state,
                materias: action.payload,
                loading: false
            };
        case UPDATE_MATERIA:
            return {
                ...state,
                materias: action.payload,
                loading: false
            };
        case SELECTED_MATERIA:
            return {
                ...state,
                materia: action.payload
            };
        case LOADING_MATERIAS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}