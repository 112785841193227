import {LOADING_ROLES, ROLES, SELECTED_ROL} from './../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import Functions from '../../../components/Global/Functions';

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_ROLES,
        payload: data
    };
}

export function createRole(token, name, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'roles/create/' + name, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchRoles(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchRoles(token));
        });
    };
}

export function fetchRoles(token) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'roles/get', {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchRolesSuccess(response.data));
        }).catch(error => {
            dispatch(fetchRolesFailed());
        });
    };
}

export function updateRole(token, nombre, fd, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + 'roles/update/' + nombre + '/' + id, fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchRoles(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchRoles(token));
        });
    };
}

export function deleteRole(token, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'roles/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            Functions.alertSuccess();
            dispatch(fetchRoles(token));
        }).catch(error => {
            Functions.alertFail();
            dispatch(fetchRoles(token));
        });
    };
}

export function fetchRolesSuccess(response) {
    return {
        type: ROLES,
        payload: response
    };
}

export function fetchRolesFailed() {
    Functions.alertFail();
    return {
        type: ROLES,
        payload: []
    };
}

export function setRole(role) {
    return {
        type: SELECTED_ROL,
        payload: role
    };
}
