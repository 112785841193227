import React, {useState} from 'react';
import {ChromePicker} from 'react-color';
import reactCSS from 'reactcss';
import {useDispatch, useSelector} from 'react-redux';
import {setColor} from '../../redux/actions/CountriesActions/TypeEventsActions';


function ColorPicker(props) {
    const [displayColorPicker, setDisplayColorPicker] = useState('');
    const color = useSelector(state => state.type_events.color);
    const dispatch = useDispatch();
    const styles = reactCSS({
        default: {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${color.r}, ${color.g}, ${
                    color.b}, ${color.a})`
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer'
            },
            popover: {
                position: 'absolute',
                zIndex: '2'
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px'
            }
        }
    });

    if (props.solo) {
        return (

            <ChromePicker color={color} onChange={(color) => dispatch(setColor(color.hex))}/>
        );
    } else {
        return (
            <div>
                <div style={styles.swatch} onClick={() => setDisplayColorPicker(true)}>
                    <div style={styles.color}/>
                </div>
                {displayColorPicker ? (
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={() => setDisplayColorPicker(false)}/>
                        <ChromePicker styles={{width: '100%'}} color={color}
                                      onChange={(color) => dispatch(setColor(color.hex))}/>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default ColorPicker;
